import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useAppSelector } from '../hooks/global';
import { ROUTES } from './routes';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../themes/main';
import { MainLayout, SimpleLayout } from '../components/common';
import {
  SignIn,
  Chemicals,
  NotFound,
  Chemical,
  Import,
  Glossary,
  Users,
  User,
  SignUp,
  SetPassword,
  ForgotPassword,
  Inquiries,
  Inquiry,
  Analytics,
} from '../components/pages';
import Account from '../components/pages/Account/Account';
import ChemicalDetails from '../components/pages/ChemicalDetails/Chemical';

const Router = () => {
  const { accessToken, role } = useAppSelector(state => state.auth);
  const isAuthenticated = !!localStorage.getItem('access_token') || !!accessToken;
  const isAdmin = localStorage.getItem('role') === 'admin' || role === 'admin';

  return (
    <ThemeProvider theme={defaultTheme}>
      <Routes>
        <Route
          path={ROUTES.SIGN_IN}
          element={
            <SimpleLayout>
              <SignIn />
            </SimpleLayout>
          }
        />
        <Route
          path={ROUTES.SIGN_UP}
          element={
            <SimpleLayout>
              <SignUp />
            </SimpleLayout>
          }
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          element={
            <SimpleLayout>
              <ForgotPassword />
            </SimpleLayout>
          }
        />
        <Route
          path={ROUTES.SET_PASSWORD}
          element={
            <SimpleLayout>
              <SetPassword />
            </SimpleLayout>
          }
        />
        {isAuthenticated && (
          <>
            <Route path="/" element={<Navigate to={ROUTES.CHEMICALS} />} />
            <Route
              path={ROUTES.CHEMICALS}
              element={
                <MainLayout>
                  <Chemicals />
                </MainLayout>
              }
            />
            <Route
              path={ROUTES.CHEMICAL}
              element={
                <MainLayout>
                  <Chemical />
                </MainLayout>
              }
            />
            <Route
              path={ROUTES.CHEMICAL_DETAILS}
              element={
                <MainLayout>
                  <ChemicalDetails />
                </MainLayout>
              }
            />
            <Route
              path={ROUTES.CHEMICAL_CREATE}
              element={
                <MainLayout>
                  <Chemical />
                </MainLayout>
              }
            />
            <Route
              path={ROUTES.GLOSSARY}
              element={
                <MainLayout>
                  <Glossary />
                </MainLayout>
              }
            />
            <Route
              path={ROUTES.ACCOUNT}
              element={
                <MainLayout>
                  <Account />
                </MainLayout>
              }
            />
            {isAdmin && (
              <>
                <Route
                  path={ROUTES.IMPORT}
                  element={
                    <MainLayout>
                      <Import />
                    </MainLayout>
                  }
                />
                <Route
                  path={ROUTES.USERS}
                  element={
                    <MainLayout>
                      <Users />
                    </MainLayout>
                  }
                />
                <Route
                  path={ROUTES.USER}
                  element={
                    <MainLayout>
                      <User />
                    </MainLayout>
                  }
                />
                <Route
                  path={ROUTES.USER_CREATE}
                  element={
                    <MainLayout>
                      <User />
                    </MainLayout>
                  }
                />
                <Route
                  path={ROUTES.ANALYTICS}
                  element={
                    <MainLayout>
                      <Analytics />
                    </MainLayout>
                  }
                />
                <Route
                  path={ROUTES.INQUIRIES}
                  element={
                    <MainLayout>
                      <Inquiries />
                    </MainLayout>
                  }
                />
                <Route
                  path={ROUTES.INQUIRY}
                  element={
                    <MainLayout>
                      <Inquiry />
                    </MainLayout>
                  }
                />
              </>
            )}
            <Route
              path="*"
              element={
                <SimpleLayout isCopyright={false}>
                  <NotFound />
                </SimpleLayout>
              }
            />
          </>
        )}
        <Route path="*" element={<Navigate to={ROUTES.SIGN_IN} replace />} />
      </Routes>
    </ThemeProvider>
  );
    return (
        <ThemeProvider theme={defaultTheme}>
            <Routes>
                <Route path={ROUTES.SIGN_IN} element={
                    <SimpleLayout>
                        <SignIn/>
                    </SimpleLayout>
                }/>
                <Route path={ROUTES.SIGN_UP} element={
                    <SimpleLayout>
                        <SignUp/>
                    </SimpleLayout>
                }/>
                <Route path={ROUTES.FORGOT_PASSWORD} element={
                    <SimpleLayout>
                        <ForgotPassword/>
                    </SimpleLayout>
                }/>
                <Route path={ROUTES.SET_PASSWORD} element={
                    <SimpleLayout>
                        <SetPassword/>
                    </SimpleLayout>
                }/>
                {(isAuthenticated) && (
                    <>
                        <Route path="/" element={<Navigate to={ROUTES.CHEMICALS}/>}/>
                        <Route path={ROUTES.CHEMICALS} element={<MainLayout><Chemicals/></MainLayout>}/>
                        <Route path={ROUTES.CHEMICAL} element={<MainLayout><Chemical/></MainLayout>}/>
                        <Route path={ROUTES.CHEMICAL_DETAILS} element={<MainLayout><ChemicalDetails/></MainLayout>}/>
                        <Route path={ROUTES.CHEMICAL_CREATE} element={<MainLayout><Chemical/></MainLayout>}/>
                        <Route path={ROUTES.GLOSSARY} element={<MainLayout><Glossary/></MainLayout>}/>
                        <Route path={ROUTES.ACCOUNT} element={<MainLayout><Account/></MainLayout>}/>
                        {isAdmin && (
                            <>
                                <Route path={ROUTES.IMPORT} element={<MainLayout><Import/></MainLayout>}/>
                                <Route path={ROUTES.USERS} element={<MainLayout><Users/></MainLayout>}/>
                                <Route path={ROUTES.USER} element={<MainLayout><User/></MainLayout>}/>
                                <Route path={ROUTES.USER_CREATE} element={<MainLayout><User/></MainLayout>}/>
                            </>
                        )}
                        <Route path="*" element={<SimpleLayout isCopyright={false}><NotFound/></SimpleLayout>}/>
                    </>
                )}
                <Route path="*" element={<Navigate to={ROUTES.SIGN_IN} replace/>}/>
            </Routes>
        </ThemeProvider>
    );
};

export default Router;
