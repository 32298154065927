import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'hooks/global';
import { fetchAnalytics, setFilterParams } from 'store/analytics/analyticsSlice';
import { Button } from 'components/controls';
import DatepickerMUIStyled from 'components/controls/StyledDatepicker/DatePickerMUIStyled';
import {
  DatePickersContainer,
  ExportButtonContainer,
  FormInput,
  SearchPanelButtons,
  SearchPanelButtonsGroup,
  SearchPanelControls,
  SearchPanelWrapper,
} from './styled';
import { fileDownload } from '../../../../../store/general/generalSlice';
import api from '../../../../../service/api';

const SearchPanel = forwardRef((props, ref) => {
  const dispatch = useAppDispatch();
  const didMountRef = useRef(false);
  const location = useLocation();
  const keepFilters = location.state?.keepFilters || false;
  const { listFilters } = useAppSelector(state => state.analytics);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: listFilters.first_name || '',
      last_name: listFilters.last_name || '',
      email: listFilters.email || '',
      date_from: listFilters.date_from ? moment(listFilters.date_from) : null,
      date_to: listFilters.date_to ? moment(listFilters.date_to) : null,
    },
  });

  useImperativeHandle(ref, () => ({}));

  const handleReset = () => {
    const data = {
      first_name: '',
      last_name: '',
      email: '',
      date_from: null,
      date_to: null,
    };
    reset(data);
    dispatch(setFilterParams({ ...listFilters, ...data, page: 1 }));
  };

  useEffect(() => {
    didMountRef.current = true;
    if (!keepFilters) handleReset();
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      dispatch(fetchAnalytics(listFilters));
    }
  }, [listFilters]);

  const handleSearch = (data: any) => {
    const formattedData = {
      ...data,
      date_from: data.date_from ? data.date_from.format('YYYYMMDD') : '',
      date_to: data.date_to ? data.date_to.format('YYYYMMDD') : '',
    };

    dispatch(setFilterParams({ ...listFilters, ...formattedData, page: 1 }));
  };

  const handleExport = async () => {
    try {
      const formValues = getValues();

      const params = {
        first_name: formValues.first_name || '',
        last_name: formValues.last_name || '',
        email: formValues.email || '',
        date_from: formValues.date_from ? formValues.date_from.format('YYYYMMDD') : '',
        date_to: formValues.date_to ? formValues.date_to.format('YYYYMMDD') : '',
      };

      const response = await api.get('google-analytics/export', {
        params,
        responseType: 'blob',
      });

      const file = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const fileName = `analytics_report_${moment().format('YYYY-MM-DD')}.xlsx`;

      dispatch(fileDownload({ file, title: fileName }));
    } catch (error) {
      console.error('Error downloading analytics report:', error);
    }
  };

  return (
    <SearchPanelWrapper>
      <SearchPanelControls>
        <Controller
          name={'first_name'}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput
              {...field}
              label={'First Name'}
              placeholder={''}
              error={errors.first_name}
            />
          )}
        />
        <Controller
          name={'last_name'}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput
              {...field}
              label={'Last Name'}
              placeholder={''}
              error={errors.last_name}
            />
          )}
        />
        <Controller
          name={'email'}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput
              {...field}
              label={'Email Address'}
              placeholder={''}
              error={errors.email}
            />
          )}
        />
      </SearchPanelControls>
      <DatePickersContainer>
        <Controller
          name={'date_from'}
          control={control}
          render={({ field: { ref, value, onChange, ...rest } }) => (
            <DatepickerMUIStyled
              label={'Date From'}
              value={value}
              onChange={onChange}
              {...rest}
            />
          )}
        />
        <Controller
          name={'date_to'}
          control={control}
          render={({ field: { ref, value, onChange, ...rest } }) => (
            <DatepickerMUIStyled
              label={'Date To'}
              value={value}
              onChange={onChange}
              {...rest}
            />
          )}
        />
      </DatePickersContainer>

      <SearchPanelButtons>
        <SearchPanelButtonsGroup>
          <Button
            title="SEARCH"
            color="green"
            size="LG"
            variant="secondary"
            onClick={handleSubmit(handleSearch)}
          />
          <Button
            title="RESET"
            color="red-transparent"
            size="LG"
            variant="secondary"
            onClick={handleReset}
          />
        </SearchPanelButtonsGroup>
        <ExportButtonContainer>
          <Button
            title="GET REPORT"
            color="ultraviolet"
            size="LG"
            variant="primary"
            onClick={handleExport}
          />
        </ExportButtonContainer>
      </SearchPanelButtons>
    </SearchPanelWrapper>
  );
});

SearchPanel.displayName = 'SearchPanel';

export default SearchPanel;
