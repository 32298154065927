import React, {FC, useMemo, useRef} from 'react';
import {useAppDispatch, useAppSelector} from 'hooks/global';
import PageContentContainer from "components/common/PageContentContainer/PageContentContainer";
import {DataTable} from "components/common";
import SearchPanel from "./parts/SearchPanel/SearchPanel";
import AsideBlack from "./parts/AsideBlack/AsideBlack";
import {defaultTheme} from "themes/main";
import {IUsersListItem} from "types";
import {setFilterParams} from "store/user/userSlice";
import {sectorOptions} from "constants/SelectOptions";
import {Content, H2Styled, Title, StyledLink} from "./styled";

const breadcrumbs = [
    {
        link: '/users',
        name: 'User Management Search & List View',
    }
]

const Users: FC = () => {
    const getColumns = (status: string)  => [
        {
            name: 'First Name',
            alias: 'first_name',
            selector: (row: IUsersListItem) => row.first_name,
            sortable: true,
            cell: (row: any) =><div>
                <StyledLink to={`/user/${row.id}`}>
                    <span>{row.first_name}</span>
                </StyledLink>
            </div>,
        },
        {
            name: 'Last Name',
            alias: 'last_name',
            selector: (row: IUsersListItem) => row.last_name,
            sortable: true,
        },
        {
            name: 'Email Address',
            alias: 'email',
            selector: (row: IUsersListItem) => row.email,
            sortable: true,
        },
        {
            name: 'Sector',
            alias: 'sector',
            selector: (row: IUsersListItem) => sectorOptions.find(item => item.value === row.sector)?.label ?? row.sector ,
            sortable: true,
        },
        {
            name: 'Organization Affiliation',
            alias: 'organization',
            selector: (row: IUsersListItem) => row.organization,
            sortable: true,
        },
        {
            name: 'User Role',
            alias: 'role',
            selector: (row: IUsersListItem) => row.role,
            sortable: true,
        },
        {
            name: 'Status',
            alias: 'is_active',
            selector: (row: IUsersListItem) => {
                return (row.verified_at && row.is_active)
                    ? 'Active'
                    : ((row.is_active && !row.verified_at)
                        ? 'Pending'
                        : 'Archived')
            },
            sortable: true,
            style: {
                color: defaultTheme.ultravioletColor
            }
        },
    ].filter(item => (status === 'pending' ? item.alias !== 'role' : true));
    const dispatch = useAppDispatch();
    const filterRef = useRef<any>(null);
    const {users, isLoading, listFilters, pagination} = useAppSelector(state => state.user);
    const {user, role} = useAppSelector(state => state.auth);

    const isViewOnly = useMemo(() => {
        return !(user && (role === 'admin' || role === 'staff'));
    }, [user])

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    const setStatusFilter = (tab: string) => {
        filterRef?.current?.setStatusFilter(tab);
    }

    const getStatusFilter = () => {
        return filterRef?.current?.getStatusFilter();
    }
    return (
        <PageContentContainer
            title={'User Management Search & List View'}
            isLoading={isLoading}
            breadcrumbs={breadcrumbs}
            aside={
                <AsideBlack setTab={setStatusFilter} tab={getStatusFilter()}/>
            }
        >
            <Title>
                <H2Styled>User Management</H2Styled>
            </Title>
            <Content>
                <SearchPanel
                    isViewOnly={isViewOnly}
                    // tab={tab}
                    ref={filterRef}
                />
                <DataTable
                    data={users}
                    columns={getColumns(getStatusFilter())}
                    currentPage={pagination.current_page}
                    paginationTotalRows={pagination.total}
                    paginationPerPage={pagination.per_page}
                    lastPage={pagination.last_page}
                    from={pagination.from}
                    to={pagination.to}
                    onChangePage={handleChangePage}
                    onSort={handleSort}
                    pagination={true}
                />
            </Content>
        </PageContentContainer>
    );
};

export default Users;
