import styled from 'styled-components';
import { Input, SingleSelect } from 'components/controls';

export const SearchPanelWrapper = styled.div``;

export const SearchPanelControls = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

export const DatePickersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

export const SearchPanelButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 70px;
`;

export const SearchPanelButtonsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const FormInput = styled(Input)`
  width: 100%;
  max-width: 277px;
  min-width: 150px;
`;

export const FormDoubleInput = styled(FormInput)`
  max-width: 574px;
`;

export const FormSingleSelect = styled(SingleSelect)`
  width: 100%;
  max-width: 277px;
`;

export const ExportButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
