import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "hooks/global";
import {fetchChemicals, fetchChemicalsExport, setFilterParams} from "store/chemical/chemicalSlice";
import {Button} from "components/controls";
import {ROUTES} from "router/routes";
import Search from "assets/images/icons/search.svg";
import {ReactComponent as Plus} from "assets/images/icons/plus.svg";
import {ReactComponent as Minus} from "assets/images/icons/minus.svg";
import {Box} from "@mui/material";
import {
    ChemicalSearchSwitcher,
    FormDoubleInput,
    FormInput,
    FormSingleSelect,
    SearchPanelButtons, SearchPanelButtonsGroup,
    SearchPanelControls,
    SearchPanelWrapper
} from "./styled";
import {fetchFunctions, fetchIndustrialSectors, fetchPolymers} from "store/general/generalSlice";

const confidenceSubstances = [
    {value: 'all', label: 'All Categories'},
    {value: 'high', label: 'High'},
    {value: 'medium', label: 'Medium'},
    {value: 'low', label: 'Low'},
]

const dataSource = [
    {value: 'confirmed-only', label: 'Confirmed Additives'},
    {value: 'confirmed-unep', label: 'Confirmed Additives + UNEP Reported Chemicals'},
]

const statuses = [
    {value: ' ', label: 'All'},
    {value: '1', label: 'Active'},
    {value: '0', label: 'Archived'},
]

type Props = {
    isViewOnly?: boolean
}

const SearchPanel: FC<Props> = ({isViewOnly}) => {
    const dispatch = useAppDispatch();
    const didMountRef = useRef(false);
    const location = useLocation();
    const navigate = useNavigate();
    const keepFilters = location.state?.keepFilters || false;
    const [isFilterCollapsed, setIsFilterCollapsed] = useState(true);
    const {listFilters, isExporting} = useAppSelector(state => state.chemical);
    const {functions, polymers, industrialSectors} = useAppSelector(state => state.general);
    const {control, handleSubmit, reset, formState: {errors}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            search: listFilters.search,
            data_source: listFilters.data_source,
            name: listFilters.name,
            casrn: listFilters.casrn,
            inchi_key: listFilters.inchi_key,
            confidence_plastic_commerce: listFilters.confidence_plastic_commerce,
            level_of_concern: listFilters.level_of_concern,
            polymer: listFilters.polymer,
            function: listFilters.function,
            industrial_sector: listFilters.industrial_sector,
            is_active: listFilters.is_active,

        }
    });

    const handleReset = () => {
        const data = {
            search: '',
            data_source: 'confirmed-only',
            name: '',
            casrn: '',
            inchi_key: '',
            confidence_plastic_commerce: '',
            level_of_concern: '',
            polymer: '',
            function: '',
            industrial_sector: '',
            is_active: '1',
        }
        reset(data);
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    useEffect(() => {
        dispatch(fetchFunctions());
        dispatch(fetchPolymers());
        dispatch(fetchIndustrialSectors());
    }, []);

    useEffect(() => {
        didMountRef.current = true;
        if (!keepFilters) handleReset();
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            dispatch(fetchChemicals(listFilters));
        }
    }, [listFilters]);

    const functionOptions = useMemo(() => {
        return functions ? functions.map((item) => ({value: item.alias, label: item.title})) : [];
    }, [functions]);

    const polymerOptions = useMemo(() => {
        return polymers ? polymers.map((item) => ({value: item.alias, label: item.title})) : [];
    }, [polymers]);

    const industrialSectorOptions = useMemo(() => {
        return industrialSectors ? industrialSectors.map((item) => ({value: item.alias, label: item.title})) : [];
    }, [industrialSectors]);

    const handleSearch = (data: any) => {
        dispatch(setFilterParams({...listFilters, ...data, page: 1}));
    };

    const handleExport = () => {
        dispatch(fetchChemicalsExport());
    }

    return (
        <SearchPanelWrapper>
            <Box display="flex" gap="20px">
                <Controller
                    name={"search"}
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <FormDoubleInput
                            {...field}
                            label={'Chemical Search'}
                            placeholder={''}
                            icon={Search}
                            error={errors.search}
                        />
                    )}
                />
                <Controller
                    name={"data_source"}
                    control={control}
                    render={({field: {ref, ...field}}) => (
                        <FormSingleSelect
                            {...field}
                            label={'Data Source'}
                            options={dataSource}
                            error={errors.data_source}
                        />
                    )}
                />
            </Box>

                <ChemicalSearchSwitcher onClick={()=>setIsFilterCollapsed(prevState => !prevState)}>
                    {isFilterCollapsed ? <Plus/> : <Minus/>}
                    <p>Advanced search options</p>
                </ChemicalSearchSwitcher>
                {!isFilterCollapsed && (
                    <SearchPanelControls>
                        <Controller
                            name={"name"}
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label={'Name'}
                                    placeholder={''}
                                    error={errors.name}
                                />
                            )}
                        />
                        <Controller
                            name={"casrn"}
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label={'CASRN'}
                                    placeholder={''}
                                    error={errors.casrn}
                                />
                            )}
                        />
                        <Controller
                            name={"inchi_key"}
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label={'InChIKey'}
                                    placeholder={''}
                                    error={errors.inchi_key}
                                />
                            )}
                        />
                        <Controller
                            name={"polymer"}
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormSingleSelect
                                    {...field}
                                    label={'Polymer'}
                                    options={polymerOptions}
                                    error={errors.polymer}
                                    isSelectOne
                                />
                            )}
                        />
                        <Controller
                            name={"function"}
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormSingleSelect
                                    {...field}
                                    label={'Function'}
                                    options={functionOptions}
                                    error={errors.function}
                                    isSelectOne
                                />
                            )}
                        />
                        <Controller
                            name={"industrial_sector"}
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormSingleSelect
                                    {...field}
                                    label={'Industrial Sector'}
                                    options={industrialSectorOptions}
                                    error={errors.industrial_sector}
                                    isSelectOne
                                />
                            )}
                        />
                        <Controller
                            name={"confidence_plastic_commerce"}
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormSingleSelect
                                    {...field}
                                    label={'Confidence of Use in Plastic'}
                                    options={confidenceSubstances}
                                    error={errors.confidence_plastic_commerce}
                                    isSelectOne
                                />
                            )}
                        />
                        <Controller
                            name={"is_active"}
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormSingleSelect
                                    {...field}
                                    label={'Status'}
                                    options={statuses}
                                    error={errors.is_active}
                                />
                            )}
                        />
                    </SearchPanelControls>
                )}
            <SearchPanelButtons>
                <SearchPanelButtonsGroup>
                    <Button
                        title='SEARCH'
                        color='green'
                        size='LG'
                        variant='secondary'
                        onClick={handleSubmit(handleSearch)}
                    />
                    <Button
                        title='RESET'
                        color='red-transparent'
                        size='LG'
                        variant='secondary'
                        onClick={handleReset}
                    />
                </SearchPanelButtonsGroup>
                {!isViewOnly && (
                    <SearchPanelButtonsGroup>
                        <Button
                            title='EXPORT CHEMICALS'
                            color='terracotta-transparent'
                            size='XXXL'
                            icon={'export'}
                            variant='secondary'
                            disabled={isExporting}
                            onClick={handleExport}
                        />
                        <Button
                            title='ADD NEW CHEMICAL'
                            color='ultraviolet'
                            size='XXXL'
                            icon={'add'}
                            variant='secondary'
                            disabled={isExporting}
                            onClick={()=>navigate(ROUTES.CHEMICAL_CREATE)}
                        />
                    </SearchPanelButtonsGroup>
                )}
            </SearchPanelButtons>
        </SearchPanelWrapper>
    );
};

export default SearchPanel;
