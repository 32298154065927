export enum ROUTES {
  HOME = '/',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  SET_PASSWORD = '/set-password',
  FORGOT_PASSWORD = '/forgot-password',
  CHEMICALS = '/chemicals',
  CHEMICAL = '/chemical/:id',
  CHEMICAL_DETAILS = '/chemical-details/:id',
  CHEMICAL_CREATE = '/chemical/new',
  IMPORT = '/import',
  GLOSSARY = '/glossary',
  USERS = '/users',
  USER = '/user/:id',
  USER_CREATE = '/user/new',
  ACCOUNT = '/account',
  INQUIRIES = '/inquiries',
  INQUIRY = '/inquiry/:id',
  ANALYTICS = '/activity',
}
