import React, { FC, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/global';
import PageContentContainer from 'components/common/PageContentContainer/PageContentContainer';
import { DataTable } from 'components/common';
import SearchPanel from './parts/SearchPanel/SearchPanel';
import { Content, H2Styled, Title } from './styled';
import { setFilterParams } from 'store/analytics/analyticsSlice';

const breadcrumbs = [
  {
    link: '/analytics',
    name: 'User Analytics',
  },
];

const Analytics: FC = () => {
  const columns = [
    {
      name: 'User',
      alias: 'user',
      selector: (row: any) => `${row.first_name} ${row.last_name}`,
      sortable: true,
    },
    {
      name: 'Role',
      alias: 'user_role',
      selector: (row: any) => row.user_role,
      sortable: true,
    },
    {
      name: 'Email',
      alias: 'email',
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: 'Phone',
      alias: 'phone',
      selector: (row: any) => row.phone,
      sortable: true,
    },
    {
      name: 'Country',
      alias: 'country',
      selector: (row: any) => row.country,
      sortable: true,
    },
    {
      name: 'Sector',
      alias: 'sector',
      selector: (row: any) => row.country,
      sortable: true,
    },
    {
      name: 'Organization',
      alias: 'organization_affiliation',
      selector: (row: any) => row.organization_affiliation,
      sortable: true,
    },
    {
      name: 'Status',
      alias: 'status',
      selector: (row: any) => row.status,
      sortable: true,
    },
    {
      name: '# of Sessions by User',
      alias: 'sessions_count',
      selector: (row: any) => row.sessions_count,
      sortable: true,
    },
    {
      name: 'Session time by User',
      alias: 'session_time',
      selector: (row: any) => row.session_time,
      sortable: true,
    },
    {
      name: 'Avg. # of pages accessed by User',
      alias: 'avg_pages',
      selector: (row: any) => row.avg_pages,
      sortable: true,
    },
    {
      name: 'Last Login',
      alias: 'last_login',
      selector: (row: any) => row.last_login,
      sortable: true,
    },
  ];

  const dispatch = useAppDispatch();
  const filterRef = useRef<any>(null);
  const { analytics, isLoading, listFilters, pagination } = useAppSelector(
    state => state.analytics,
  );

  const handleChangePage = (page: number) => {
    dispatch(setFilterParams({ ...listFilters, page }));
  };

  const handleSort = (order_by: string, order_dir: string) => {
    dispatch(setFilterParams({ ...listFilters, order_by, order_dir, page: 1 }));
  };

  return (
    <PageContentContainer
      title={'User Analytics'}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <Title>
        <H2Styled>User Analytics</H2Styled>
      </Title>
      <Content>
        <SearchPanel ref={filterRef} />
        <DataTable
          data={analytics}
          columns={columns}
          currentPage={pagination.current_page}
          paginationTotalRows={pagination.total}
          paginationPerPage={pagination.per_page}
          lastPage={pagination.last_page}
          from={pagination.from}
          to={pagination.to}
          onChangePage={handleChangePage}
          onSort={handleSort}
          pagination={true}
        />
      </Content>
    </PageContentContainer>
  );
};

export default Analytics;
