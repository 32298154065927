import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useAppDispatch, useAppSelector } from 'hooks/global';
import { ROUTES } from 'router/routes';
import { ITab } from 'types';
import { ConfirmationModal, PageContentContainer } from 'components/common';
import {
  fetchChemicalById,
  fetchCreateChemical,
  fetchUpdateChemical,
  resetChemicalDetails,
} from 'store/chemical/chemicalSlice';
import { Buttons, FormView, ListView, Aside } from './parts';
import { Content, Wrapper, H2Styled, Title, HeaderWrapper } from './styled';
import { tabsList } from './parts/tabsList';
import HelpButton from './parts/HelpButton/HelpButton';
import { pageView, trackEvent } from 'service/analytics';

const breadcrumbs = [
  {
    link: '/chemicals',
    name: 'Search & List View',
  },
  {
    link: '',
    name: 'Chemical Details',
  },
];

const ChemicalDetails: FC = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formRef = useRef<{ handleSave: () => void } | null>(null);
  const { chemical, isLoading } = useAppSelector(state => state.chemical);
  const [tab, setTab] = useState<ITab['alias']>('overview');
  const [isDescShown, setIsDescShown] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isCancelModalShown, setIsCancelModalShown] = useState(false);

  // const isViewOnly = useMemo(()=>{
  //     return !(role === 'admin' || role === 'staff');
  // }, [role])

  const isViewOnly = true;

  const isNewChemical = useMemo(() => {
    return pathname === '/chemical/new';
  }, [pathname]);

  useEffect(() => {
    if (id) {
      dispatch(fetchChemicalById(id)).then(resp => {
        if (resp.type === 'chemical/get/rejected') {
          navigate(ROUTES.CHEMICALS, { state: { keepFilters: true } });
        }
      });
    }
    return () => {
      dispatch(resetChemicalDetails());
    };
  }, [id]);

  useEffect(() => {
    if (isNewChemical) {
      setIsEditMode(true);
    }
  }, [pathname]);

  useEffect(() => {
    setIsPageLoading(true);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 300);
  }, [isEditMode, tab, isDescShown]);

  useEffect(() => {
    pageView(`chemical-details/${id}/${tab}`);
  }, [tab]);

  const handleSave = () => {
    formRef?.current?.handleSave();
  };

  const handleCancel = () => {
    if (isFormUpdated) {
      setIsCancelModalShown(true);
    } else {
      if (isNewChemical) {
        handleBack();
      } else {
        setIsEditMode(false);
      }
    }
  };

  const handleBack = () => {
    navigate(ROUTES.CHEMICALS, { state: { keepFilters: true } });
  };

  const handleFormSubmit = (data: any) => {
    if (isNewChemical) {
      dispatch(fetchCreateChemical({ data })).then(resp => {
        if (resp?.type === 'chemical/create/fulfilled') {
          navigate(`/chemical/${resp.payload.data.resource.id}`, { replace: true });
          setIsEditMode(false);
        }
      });
    } else if (id) {
      dispatch(fetchUpdateChemical({ id, data, cb: () => setIsEditMode(false) }));
    }
  };

  const handleSetEditMode = () => {
    setIsEditMode(true);
  };

  const handleChemicalArchive = (active: boolean) => {
    dispatch(fetchUpdateChemical({ id, data: { is_active: active } }));
  };

  const handleCancelModalShownConfirm = () => {
    if (isNewChemical) {
      handleBack();
    } else {
      setIsEditMode(false);
    }
    setIsCancelModalShown(false);
  };

  const handleCancelModalShownCancel = () => {
    setIsCancelModalShown(false);
  };

  const handleModalComponentHandler = ({ handleModalLeave, handleModalCancel }: any) => {
    return isFormUpdated ? (
      <ConfirmationModal
        isOpen={true}
        onClose={handleModalCancel}
        onConfirm={handleModalLeave}
        text={
          'Your form changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'
        }
      />
    ) : (
      handleModalLeave()
    );
  };

  return (
    <PageContentContainer
      title={'Chemical Details'}
      isLoading={isLoading || isPageLoading}
      breadcrumbs={breadcrumbs}
      aside={
        <Aside
          setTab={setTab}
          tabs={tabsList}
          tab={tab}
          setIsDescShown={setIsDescShown}
          isLimitedData={!!chemical?.is_limited_data}
          isVerified={
            chemical && chemical.verified_in_commerce !== null
              ? chemical.verified_in_commerce
              : undefined
          }
        />
      }
    >
      <Title>
        <HeaderWrapper>
          <H2Styled $isViewOnly={isViewOnly}>Chemical Details: {chemical?.name}</H2Styled>
        </HeaderWrapper>
        <Buttons
          isEditMode={isEditMode}
          isNewChemical={isNewChemical}
          isViewOnly={isViewOnly}
          isActive={!!chemical && !isEditMode && !!chemical.is_active}
          handleSave={handleSave}
          handleCancel={handleCancel}
          handleBack={handleBack}
          handleSetEditMode={handleSetEditMode}
          handleChemicalArchive={handleChemicalArchive}
        />
      </Title>
      <Content>
        <Wrapper>
          {isEditMode ? (
            <BeforeUnloadComponent
              blockRoute={true}
              ignoreBeforeunloadDocument={!isFormUpdated}
              ignoreChildrenLinks={true}
              modalComponentHandler={handleModalComponentHandler}
            >
              <FormView
                tab={tab}
                chemical={chemical}
                isDescShown={isDescShown}
                handleFormSubmit={handleFormSubmit}
                setIsFormUpdated={setIsFormUpdated}
                ref={formRef}
              />
            </BeforeUnloadComponent>
          ) : (
            <ListView tab={tab} chemical={chemical} isDescShown={isDescShown} />
          )}
        </Wrapper>
        <ConfirmationModal
          isOpen={isCancelModalShown}
          onClose={handleCancelModalShownCancel}
          onConfirm={handleCancelModalShownConfirm}
          text={
            'Your form changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'
          }
        />
      </Content>
      <HelpButton />
    </PageContentContainer>
  );
};

export default ChemicalDetails;
