import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useAppDispatch, useAppSelector } from 'hooks/global';
import { ConfirmationModal, PageContentContainer, Spinner } from 'components/common';
import { Button } from 'components/controls';
import { ICountry, INQUIRY_PURPOSE_OPTIONS, ISelectOption } from 'types/index';
import { ROUTES } from '../../../router/routes';
import {
  fetchInquiryById,
  resetCurrentInquiry,
  updateInquiry,
} from '../../../store/inquiry/inquirySlice';
import { fetchCountries } from '../../../store/general/generalSlice';
import { sectorOptions } from '../../../constants/SelectOptions';
import {
  REVIEW_SECTION_OPTIONS,
  STATUS_OPTIONS,
} from '../../../constants/inquiryOptions';
import {
  Content,
  FieldsWrapper,
  H2Styled,
  Title,
  HeaderWrapper,
  FormInput,
  FormSingleSelect,
  TextareaStyled,
  FormLinkInput,
  ButtonsWrapper,
} from './styled';

const Inquiry = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCancelModalShown, setIsCancelModalShown] = useState(false);
  const { countries } = useAppSelector(state => state.general);
  const { currentInquiry, isLoading } = useAppSelector(state => state.inquiry);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      country_id: 1,
      sector: '',
      organization: '',
      inquiry_type: '',
      description: '',
      casrn: '',
      additive_name: '',
      section: 'overview',
      supporting_link: '',
      status: 'new',
      resolution_details: '',
    },
  });

  const watchInquiryType = watch('inquiry_type');

  const countriesOptions = useMemo(() => {
    return countries
      ? countries.map(
          (item: ICountry): ISelectOption => ({ label: item.title, value: item.id }),
        )
      : [];
  }, [countries]);

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchInquiryById(id)).then(resp => {
        if (resp.type === 'inquiries/get/rejected') {
          navigate(ROUTES.INQUIRIES, { state: { keepFilters: true } });
        }
      });
    }
    return () => {
      dispatch(resetCurrentInquiry());
    };
  }, [id]);

  useEffect(() => {
    if (currentInquiry) {
      reset({
        name: `${currentInquiry.user?.profile?.first_name ?? ''} ${
          currentInquiry.user?.profile?.last_name ?? ''
        }`,
        email: currentInquiry.user?.email ?? '',
        country_id: currentInquiry.user?.address?.country_id ?? 1,
        sector: currentInquiry.user?.profile.sector ?? '',
        organization: currentInquiry.user?.profile.organization ?? '',
        inquiry_type: currentInquiry?.purpose ?? '',
        description: currentInquiry?.description ?? '',
        casrn: currentInquiry?.casrn ?? '',
        additive_name: currentInquiry?.additive_name ?? '',
        section: currentInquiry?.reviewed_section ?? '',
        supporting_link: currentInquiry?.link ?? '',
        status: currentInquiry?.status ?? '',
        resolution_details: currentInquiry?.resolution ?? '',
      });
    }
  }, [currentInquiry]);

  const renderBasicFields = useMemo(
    () => (
      <FieldsWrapper>
        <Controller
          name="name"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput {...field} label="Name" placeholder="Name" readOnly />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput
              {...field}
              label="Email Address"
              placeholder="Email Address"
              readOnly
            />
          )}
        />
        <Controller
          name="country_id"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormSingleSelect
              {...field}
              label="Country"
              options={countriesOptions}
              readOnly
            />
          )}
        />
        <Controller
          name="sector"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormSingleSelect
              {...field}
              label="Sector"
              options={sectorOptions}
              readOnly
            />
          )}
        />
        <Controller
          name="organization"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput
              {...field}
              label="Organization"
              placeholder="Organization Affiliation"
              readOnly
            />
          )}
        />
        <Controller
          name="inquiry_type"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormSingleSelect
              {...field}
              label="Purpose of Inquiry"
              options={INQUIRY_PURPOSE_OPTIONS}
              readOnly
            />
          )}
        />
      </FieldsWrapper>
    ),
    [currentInquiry],
  );

  const renderDescription = useMemo(
    () => (
      <Controller
        name="description"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <TextareaStyled
            {...field}
            label={
              watchInquiryType === 'data-correction'
                ? 'Description of the data issue'
                : watchInquiryType === 'new-information'
                ? 'Please provide any additional information about the additive record'
                : 'Briefly describe your inquiry'
            }
            readOnly
          />
        )}
      />
    ),
    [watchInquiryType, currentInquiry],
  );

  const renderDataUpdateFields = useMemo(
    () => (
      <FieldsWrapper>
        <Controller
          name="casrn"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput {...field} label="CASRN #" placeholder="CASRN #" readOnly />
          )}
        />
        <Controller
          name="additive_name"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput
              {...field}
              label="Additive Name"
              placeholder="Additive Name"
              readOnly
            />
          )}
        />
        <Controller
          name="section"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormSingleSelect
              {...field}
              label="Section to be Reviewed"
              options={REVIEW_SECTION_OPTIONS}
              readOnly
            />
          )}
        />
        {renderDescription}
        <Controller
          name="supporting_link"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormLinkInput
              {...field}
              label="Supporting Documentation Link"
              placeholder="Supporting Documentation Link"
              readOnly
            />
          )}
        />
      </FieldsWrapper>
    ),
    [watchInquiryType, currentInquiry],
  );

  const renderAdminFields = () => (
    <FieldsWrapper>
      <Controller
        name="status"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field: { ref, ...field } }) => (
          <FormSingleSelect
            {...field}
            label="Status"
            options={STATUS_OPTIONS}
            error={errors.status}
            disabled={isLoading}
          />
        )}
      />
      <Controller
        name="resolution_details"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <TextareaStyled
            {...field}
            label="Resolution Details"
            error={errors.resolution_details}
            disabled={isLoading}
          />
        )}
      />
    </FieldsWrapper>
  );

  const handleFormSubmit = (data: any) => {
    if (id) {
      dispatch(
        updateInquiry({
          id,
          data: {
            status: data.status,
            resolution: data.resolution_details,
          },
        }),
      ).then(() => {
        navigate(ROUTES.INQUIRIES, { state: { keepFilters: false } });
      });
    }
  };

  const handleSave = () => {
    handleSubmit(handleFormSubmit)();
  };

  const handleBack = () => {
    if (isDirty) {
      setIsCancelModalShown(true);
    } else {
      navigate(ROUTES.INQUIRIES, { state: { keepFilters: true } });
    }
  };

  const handleCancelModalShownCancel = () => {
    setIsCancelModalShown(false);
  };

  const handleCancelModalShownConfirm = () => {
    setIsCancelModalShown(false);
    navigate(ROUTES.INQUIRIES, { state: { keepFilters: true } });
  };

  const handleModalComponentHandler = ({ handleModalLeave, handleModalCancel }: any) => {
    return isDirty ? (
      <ConfirmationModal
        isOpen={true}
        onClose={handleModalCancel}
        onConfirm={handleModalLeave}
        text={
          'Your Inquiry changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'
        }
      />
    ) : (
      handleModalLeave()
    );
  };

  return (
    <PageContentContainer
      title={'Inquiry Details'}
      breadcrumbs={[
        { link: '/inquiries', name: 'Contact Inquiries Search & List View' },
        { link: '', name: isLoading ? 'Loading...' : `Inquiry Details` },
      ]}
    >
      <Spinner isLoading={isLoading} />
      <Title>
        <HeaderWrapper>
          <H2Styled>
            {`Inquiry Details: ${
              isLoading
                ? 'Loading...'
                : `${currentInquiry?.user?.profile.first_name} ${currentInquiry?.user?.profile.last_name}`
            }`}
          </H2Styled>
        </HeaderWrapper>
        <ButtonsWrapper>
          <Button
            title={'Save'}
            color={'red-transparent'}
            size="SM"
            icon="save"
            onClick={handleSave}
            disabled={!isDirty}
          />
          <Button
            title={'Back'}
            color={'blue-transparent'}
            size="SM"
            icon="back"
            onClick={handleBack}
          />
        </ButtonsWrapper>
      </Title>
      <BeforeUnloadComponent
        blockRoute={true}
        ignoreBeforeunloadDocument={!isDirty}
        ignoreChildrenLinks={true}
        modalComponentHandler={handleModalComponentHandler}
      >
        <Content>
          {renderBasicFields}
          {(currentInquiry &&
            ['data-correction', 'new-information'].includes(currentInquiry.purpose)) ||
          (watchInquiryType &&
            ['data-correction', 'new-information'].includes(watchInquiryType)) ? (
            renderDataUpdateFields
          ) : (
            <FieldsWrapper>{renderDescription}</FieldsWrapper>
          )}
          {id && renderAdminFields()}
        </Content>
      </BeforeUnloadComponent>
      <Button
        type="button"
        title={'Save'}
        color={'terracotta'}
        size="LG"
        onClick={handleSave}
        variant={'primary'}
        style={{ fontSize: '20px' }}
        disabled={!isDirty}
      />
      <ConfirmationModal
        isOpen={isCancelModalShown}
        onClose={handleCancelModalShownCancel}
        onConfirm={handleCancelModalShownConfirm}
        text={
          'Your Inquiry changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'
        }
      />
    </PageContentContainer>
  );
};

export default Inquiry;
