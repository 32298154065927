import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/global';
import { fetchLogout, fetchUser } from 'store/auth/authSlice';
import { ROUTES } from 'router/routes';
import logo from 'assets/images/logo-header.png';
import { ReactComponent as LogoutImg } from 'assets/images/icons/logout.svg';
import { ReactComponent as UsersImg } from 'assets/images/icons/users.svg';
import { ReactComponent as User } from 'assets/images/icons/user-field_ic.svg';
import {
  Img,
  StyledHeader,
  UlStyled,
  StyledLink,
  Menu,
  MenuHeader,
  MenuH2Styled,
  Hamburger,
  PopupMenuList,
  PopupMenuItem,
  PopupMenuLink,
  MenuCloseImgStyled,
  StyledALink,
} from './styled';
import { APP_URI } from '../../../constants/api';

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const { role } = useAppSelector(state => state.auth);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (menuRef?.current && !menuRef.current.contains(e.target as HTMLDivElement)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  const handleLogout = () => {
    dispatch(fetchLogout());
  };

  return (
    <StyledHeader>
      <a href={'/chemicals'}>
        <Img src={logo} />
      </a>
      <UlStyled>
        <StyledLink
          to={ROUTES.CHEMICALS}
          $isActive={splitLocation[1].indexOf('chemical') >= 0}
        >
          SEARCH
        </StyledLink>
        <StyledLink
          to={ROUTES.GLOSSARY}
          $isActive={splitLocation[1].indexOf('glossary') >= 0}
        >
          GLOSSARY
        </StyledLink>
        {role === 'admin' && (
          <StyledLink
            to={ROUTES.IMPORT}
            $isActive={splitLocation[1].indexOf('import') >= 0}
          >
            IMPORT
          </StyledLink>
        )}
        {role !== 'admin' && (
          <StyledALink
            href={`${APP_URI}/ICCA-Database-Welcome-FAQ.pdf`}
            $isActive={splitLocation[1].indexOf('faq') >= 0}
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </StyledALink>
        )}
      </UlStyled>
      <Hamburger onClick={() => setMenuOpen(!isMenuOpen)} />
      {isMenuOpen && (
        <Menu ref={menuRef}>
          <MenuHeader>
            <MenuH2Styled>Menu</MenuH2Styled>
            <MenuCloseImgStyled onClick={() => setMenuOpen(!isMenuOpen)} />
          </MenuHeader>
          <PopupMenuList>
            <PopupMenuItem>
              <User />
              <PopupMenuLink to={ROUTES.ACCOUNT} onClick={() => setMenuOpen(false)}>
                My Profile
              </PopupMenuLink>
            </PopupMenuItem>
            {role === 'admin' && (
              <PopupMenuItem>
                <UsersImg />
                <PopupMenuLink onClick={() => setMenuOpen(false)} to={ROUTES.INQUIRIES}>
                  Contact Inquiries
                </PopupMenuLink>
              </PopupMenuItem>
            )}
            {role === 'admin' && (
              <>
                <PopupMenuItem>
                  <UsersImg />
                  <PopupMenuLink onClick={() => setMenuOpen(false)} to={ROUTES.USERS}>
                    User Management
                  </PopupMenuLink>
                </PopupMenuItem>
                <PopupMenuItem>
                  <UsersImg />
                  <PopupMenuLink onClick={() => setMenuOpen(false)} to={ROUTES.ANALYTICS}>
                    User Analytics
                  </PopupMenuLink>
                </PopupMenuItem>
              </>
            )}
            <PopupMenuItem>
              <LogoutImg />
              <PopupMenuLink to="#" onClick={handleLogout}>
                Logout
              </PopupMenuLink>
            </PopupMenuItem>
          </PopupMenuList>
        </Menu>
      )}
    </StyledHeader>
  );
};

export default Header;
