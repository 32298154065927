import { configureStore } from '@reduxjs/toolkit';
import generalReducer from './general/generalSlice';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import chemicalSlice from './chemical/chemicalSlice';
import importSlice from './import/importSlice';
import alertSlice from './alert/alertSlice';
import glossarySlice from './glossary/glossarySlice';
import analyticsSlice from './analytics/analyticsSlice';
import inquirySlice from './inquiry/inquirySlice';

export const store = configureStore({
  reducer: {
    general: generalReducer,
    auth: authReducer,
    user: userReducer,
    chemical: chemicalSlice,
    import: importSlice,
    alert: alertSlice,
    glossary: glossarySlice,
    analytics: analyticsSlice,
    inquiry: inquirySlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
