import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import FileSaver from 'file-saver';
import {ICountry, IEntityType, IState} from "../../types";

export const fetchCountries = createAsyncThunk('general/countries', async (_, {dispatch}) => {
    const response = await api.get('general/countries');
    dispatch(setCountries(response.data));
});

export const fetchStates = createAsyncThunk('general/states', async (_, {dispatch}) => {
    const response = await api.get('general/states');
    dispatch(setStates(response.data));
});

export const fetchPolymers = createAsyncThunk('general/polymers', async (_, {dispatch}) => {
    const response = await api.get('general/polymers');
    dispatch(setPolymers(response.data));
});

export const fetchFunctions = createAsyncThunk('general/functions', async (_, {dispatch}) => {
    const response = await api.get('general/functions');
    dispatch(setFunctions(response.data));
});

export const fetchIndustrialSectors = createAsyncThunk('general/industrial-sectors', async (_, {dispatch}) => {
    const response = await api.get('general/industrial-sectors');
    dispatch(setIndustrialSectors(response.data));
});

export const fetchFileUpload = createAsyncThunk('file/upload', async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await api.post('file/upload', formData);
    return response.data;
});

interface GlobalState {
    isLoading: boolean;
    states: Array<IState>,
    countries: Array<ICountry>,
    polymers: Array<IEntityType>
    functions: Array<IEntityType>
    industrialSectors: Array<IEntityType>
    uploader: any
}

const initialState: GlobalState = {
    isLoading: false,
    states: [],
    countries: [],
    polymers: [],
    functions: [],
    industrialSectors: [],
    uploader: null
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setCountries: (state, action) => {
            state.countries = action.payload.data.resource;
        },
        setStates: (state, action) => {
            state.states = action.payload.data.resource;
        },
        setPolymers: (state, action) => {
            state.polymers = action.payload.data.resource;
        },
        setFunctions: (state, action) => {
            state.functions = action.payload.data.resource;
        },
        setIndustrialSectors: (state, action) => {
            state.industrialSectors = action.payload.data.resource;
        },
        resetUploader: (state) => {
            state.uploader = initialState.uploader;
        },
        fileDownload: (state, action) => {
            FileSaver.saveAs(action.payload.file, action.payload.title);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchFileUpload.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchFileUpload.fulfilled, (state: any, action: any) => {
                state.uploader = action.payload.data;
                state.isLoading = false;
            })
            .addCase(fetchFileUpload.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(state=> {
                state.isLoading = false;
            });
    },
});

export const {setCountries, setStates, setFunctions, setPolymers, setIndustrialSectors, resetUploader, fileDownload} = generalSlice.actions;
export default generalSlice.reducer;
