import React, {useEffect, useMemo, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BeforeUnloadComponent from 'react-beforeunload-component';
import {useAppDispatch, useAppSelector} from "hooks/global";
import {fetchUserById, fetchUserCreate, fetchUserUpdate, resetUserDetails} from "store/user/userSlice";
import {ICountry, ISelectOption, IUserFormData} from "types";
import {ConfirmationModal, PageContentContainer} from "components/common";
import {Buttons} from "./parts";
import {ROUTES} from "router/routes";
import {Label, Radio} from "components/controls";
import {fetchCountries, fetchStates} from "store/general/generalSlice";
import {emailPattern} from "helpers/validationRules";
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
    Content,
    FieldsWrapper,
    H2Styled,
    Radios,
    RoleWrapper,
    Title,
    HeaderWrapper,
    FormInput,
    FormSingleSelect, FormPhoneInput, TextareaStyled
} from "./styled";
import {purposeOptions, sectorOptions, statusOptions} from "constants/SelectOptions";

const breadcrumbs = [
    {
        link: '/users',
        name: 'User Management Search & List View'
    },
    {
        link: '',
        name: 'Used Details'
    }
]

const User = () => {
    const {id} = useParams();
    const {pathname} = useLocation()
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {user, isLoading} = useAppSelector(state => state.user);
    const {countries, states} = useAppSelector(state => state.general);
    const [isCancelModalShown, setIsCancelModalShown] = useState(false);
    const {user:  authUser} = useAppSelector(state => state.auth);
    const isCurrentUser = user?.id === authUser?.id;

    const {control, handleSubmit, reset, setValue, getValues, formState: {errors, isDirty, dirtyFields, touchedFields, isValid}} = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            phone: '',
            first_name: '',
            last_name: '',
            role: 'admin',
            password: '',
            country_id: 1,
            organization: '',
            function: '',
            sector: '',
            status: 'active',
        }
    });

    const sector = useWatch({
        control,
        name: 'sector',
    });

    const functionVal = useWatch({
        control,
        name: 'function',
    });

    const role = useWatch({
        control,
        name: 'role',
    });

    useEffect(() => {
        if (sector && (isNewUser || user?.profile.sector !== sector)) {
            setValue('function', '');
        }
    }, [sector]);

    // useEffect(() => {
    //     if (role && (user?.id && user.role.alias !== role || isNewUser)) {
    //         setValue('sector', '');
    //         setValue('function', '');
    //         setValue('organization', '');
    //     }
    // }, [role]);

    useEffect(() => {
        dispatch(fetchCountries());
        dispatch(fetchStates());
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(fetchUserById(id)).then(resp => {
                if (resp.type === 'users/get/rejected') {
                    navigate(ROUTES.USERS, {state: {keepFilters: true}});
                }
            });
        }
        return () => {
            dispatch(resetUserDetails());
        };
    }, [id]);

    useEffect(() => {
        if (user) {
            reset({
                email: user.email,
                phone: user.address.phone ? user.address.phone.replace('+', '') : '',
                first_name: user.profile.first_name,
                last_name: user.profile.last_name,
                role: user.role.alias,
                country_id: user.address.country_id,
                sector: user.profile.sector ?? '',
                organization: user.profile.organization ?? '',
                function: user.profile.function ?? '',
                status: (!!user.is_active && !!user.email_verified_at) ? 'active' : (
                    (!!user.is_active && !user.email_verified_at)
                        ? 'pending'
                        : 'archived'
                ),
            });
        }
    }, [user]);

    const countriesOptions = useMemo(() => {
        return countries ? countries.map((item: ICountry): ISelectOption => ({label: item.title, value: item.id})) : []
    }, [countries]);

    const handleFormSubmit = (data: IUserFormData) => {
        if (isNewUser) {
            dispatch(fetchUserCreate({data})).then(resp => {
                if (resp?.type === 'users/create/fulfilled') {
                    // navigate(`/user/${resp.payload.data.resource.id}`, { replace: true });
                    navigate(ROUTES.USERS, {state: {keepFilters: true}});
                }
            });
        } else if (id) {
            dispatch(fetchUserUpdate({id, data})).then(() => {
                navigate(ROUTES.USERS, {state: {keepFilters: true}});
            });
        }
    }

    const isNewUser = useMemo(() => {
        return (pathname === '/user/new');
    }, [pathname]);

    const handleArchive = (active: boolean) => {
        dispatch(fetchUserUpdate({id, data: {is_active: active}}));
    }

    const handleSave = () => {
        handleSubmit((data) => handleFormSubmit(data))();
    };

    const handleBack = () => {
        if (isDirty) {
            setIsCancelModalShown(true);
        } else {
            navigate(ROUTES.USERS, { state: { keepFilters: true } });
        }
    };

    const handleModalComponentHandler = ({handleModalLeave, handleModalCancel}: any) => {
        return isDirty ? (
            <ConfirmationModal
                isOpen={true}
                onClose={handleModalCancel}
                onConfirm={handleModalLeave}
                text={'Your form changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'}
            />
        ) : handleModalLeave();
    }

    const handleCancelModalShownCancel = () => {
        setIsCancelModalShown(false);
    }

    const handleCancelModalShownConfirm = () => {
        setIsCancelModalShown(false);
        navigate(ROUTES.USERS, { state: { keepFilters: true } });
    }

    return (
        <PageContentContainer title={isNewUser ? 'Create New User' : 'User Details'} isLoading={isLoading} breadcrumbs={breadcrumbs}>
            <Title>
                <HeaderWrapper>
                    <H2Styled>{isNewUser ? 'Create New User' : `User Details: ${user?.profile?.first_name}`}</H2Styled>
                </HeaderWrapper>
                <Buttons
                    isActive={!!user && !!user.is_active}
                    isNewUser={isNewUser}
                    isCurrentUser={isCurrentUser}
                    handleSave={handleSave}
                    handleBack={handleBack}
                    handleArchive={handleArchive}
                />
            </Title>
            <BeforeUnloadComponent
                blockRoute={true}
                ignoreBeforeunloadDocument={!isDirty}
                ignoreChildrenLinks={true}
                modalComponentHandler={handleModalComponentHandler}
            >
                <Content>
                    <RoleWrapper>
                        <Label>User Role</Label>
                        <Controller
                            name='role'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <Radios
                                    row={true}
                                    {...field}
                                >
                                    <Radio
                                        label='Super Administrator'
                                        value='admin'
                                    />
                                    <Radio
                                        label='Basic User'
                                        value='staff'
                                    />
                                    <Radio
                                        label='Public User'
                                        value='user'
                                    />
                                </Radios>
                            )}
                        />
                    </RoleWrapper>
                    <FieldsWrapper>
                        <Controller
                            name='first_name'
                            control={control}
                            rules={{required: 'This field is required'}}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='First Name*'
                                    placeholder='First Name'
                                    error={errors.first_name}
                                />
                            )}
                        />
                        <Controller
                            name='last_name'
                            control={control}
                            rules={{required: 'This field is required'}}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='Last Name*'
                                    placeholder='Last Name'
                                    error={errors.last_name}
                                />
                            )}
                        />
                        <Controller
                            name='email'
                            control={control}
                            rules={{
                                required: "This field is required",
                                pattern: emailPattern,
                            }}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    label='Email Address*'
                                    placeholder='Email Address'
                                    error={errors.email}
                                />
                            )}
                        />
                        <Controller
                            name='phone'
                            control={control}
                            rules={{
                                required: "This field is required",
                                validate: (value: string) => isValidPhoneNumber(`+${value}`) || 'Phone number is invalid'
                            }}
                            render={({field: {ref, ...field}}) => (
                                <FormPhoneInput
                                    {...field}
                                    label='Mobile Phone #*'
                                    error={errors.phone}
                                />
                            )}
                        />
                        <Controller
                            name='password'
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <FormInput
                                    {...field}
                                    type='password'
                                    label='Password'
                                    placeholder='Password'
                                    error={errors.password}
                                />
                            )}
                        />
                    </FieldsWrapper>
                    <FieldsWrapper>
                        {role === 'user' && (
                            <>
                                <Controller
                                    name='country_id'
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <FormSingleSelect
                                            {...field}
                                            label='Country'
                                            options={countriesOptions}
                                            error={errors.country_id}
                                        />
                                    )}
                                />
                                <Controller
                                    name='sector'
                                    rules={{required: 'This field is required'}}
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <FormSingleSelect
                                            {...field}
                                            label='Sector *'
                                            options={sectorOptions}
                                            error={errors.sector}
                                        />
                                    )}
                                />
                                {sector === 'industry'
                                    ? <Controller
                                        name='function'
                                        rules={{required: 'This field is required'}}
                                        control={control}
                                        render={({field: {ref, ...field}}) => (
                                            <FormSingleSelect
                                                {...field}
                                                label='Purpose *'
                                                options={purposeOptions}
                                                error={errors.function}
                                            />
                                        )}
                                    />
                                    : <Controller
                                        name='function'
                                        control={control}
                                        render={({field: {ref, ...field}}) => (
                                            <TextareaStyled
                                                {...field}
                                                label='Purpose'
                                                error={errors.function}
                                            />
                                        )}
                                    />
                                }
                                <Controller
                                    name='organization'
                                    rules={{required: 'This field is required'}}
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <FormInput
                                            {...field}
                                            label='Organization Affiliation*'
                                            placeholder='Organization Affiliation*'
                                            error={errors.organization}
                                        />
                                    )}
                                />
                            </>
                        )}
                        {!isNewUser && (
                            <Controller
                                name='status'
                                rules={{required: 'This field is required'}}
                                control={control}
                                render={({field: {ref, ...field}}) => (
                                    <FormSingleSelect
                                        {...field}
                                        label='Status'
                                        options={statusOptions}
                                        error={errors.status}
                                    />
                                )}
                            />
                        )}
                    </FieldsWrapper>
                </Content>
            </BeforeUnloadComponent>
            <ConfirmationModal
                isOpen={isCancelModalShown}
                onClose={handleCancelModalShownCancel}
                onConfirm={handleCancelModalShownConfirm}
                text={'Your form changes are unsaved. If you exit now, your edits will be lost. Confirm the exit?'}
            />
        </PageContentContainer>
    );
};

export default User;
