import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/global';
import { Controller, useForm } from 'react-hook-form';
import { Spinner } from 'components/common';
import { Button } from 'components/controls';
import { createInquiry } from 'store/inquiry/inquirySlice';
import { REVIEW_SECTION_OPTIONS } from 'constants/inquiryOptions';
import { INQUIRY_PURPOSE_OPTIONS } from 'types/index';
import { createNonEmptyObject } from 'helpers/commonHelpers';
import {
  FormWrapper,
  Title,
  ButtonText,
  StyledButton,
  Content,
  FieldsWrapper,
  FormInput,
  FormSingleSelect,
  TextareaStyled,
  FormLinkInput,
  FormTextStyled,
  ConditionalFormTextStyled,
} from './styled';

const HelpButton = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.auth);
  const { isLoading } = useAppSelector(state => state.inquiry);
  const [isFormVisible, setFormVisible] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      purpose: '',
      description: '',
      casrn: '',
      reviewed_section: '',
      link: '',
      additive_name: '',
    },
  });

  const watchInquiryType = watch('purpose');

  useEffect(() => {
    if (user) {
      reset({
        name: `${user.profile.first_name} ${user.profile.last_name}`,
        email: user?.email,
        purpose: '',
        description: '',
        casrn: '',
        reviewed_section: '',
        link: '',
        additive_name: '',
      });
    }
  }, [user]);

  const handleFormSubmit = async (data: any) => {
    try {
      const payload = createNonEmptyObject({
        purpose: data.purpose,
        description: data.description,
        casrn: data.casrn,
        additive_name: data.additive_name,
        reviewed_section: data.reviewed_section,
        link: data.link,
      });
      await dispatch(createInquiry(payload));
    } finally {
      setFormVisible(false);
      reset();
    }
  };

  const handleSave = () => {
    handleSubmit(handleFormSubmit)();
  };

  // if (
  //   !user?.profile?.sector ||
  //   !['government', 'industry'].includes(user.profile.sector)
  // ) {
  //   return null;
  // }

  if (user?.role?.alias !== 'user') {
    return null;
  }

  return (
    <>
      <Spinner isLoading={isLoading} />
      <StyledButton onClick={() => setFormVisible(!isFormVisible)}>
        <ButtonText>NEED TO CONTACT US? PLEASE CLICK HERE</ButtonText>
      </StyledButton>
      {isFormVisible && (
        <FormWrapper>
          <Title>Member Contact Us</Title>
          <Content>
            <FormTextStyled>
              Use this form to contact us for general inquiries, questions, or support. If
              you need to request updates or changes to the additives data, please provide
              detailed information in the relevant section below.
            </FormTextStyled>
            <FieldsWrapper>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return <FormInput {...rest} label="Your Name" readOnly />;
                }}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return <FormInput {...rest} label="Email Address" readOnly />;
                }}
              />
              <Controller
                name="purpose"
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => {
                  const { ref, ...rest } = field;
                  return (
                    <FormSingleSelect
                      {...rest}
                      label="Purpose of Inquiry"
                      options={INQUIRY_PURPOSE_OPTIONS}
                      error={errors.purpose}
                      disabled={isLoading}
                    />
                  );
                }}
              />
            </FieldsWrapper>
            {['data-correction', 'new-information'].includes(watchInquiryType) ? (
              <>
                <ConditionalFormTextStyled>
                  We strive to provide the most accurate additive data from around the
                  globe. If you see data that you have a question about the data or
                  believe it is incorrect; we want to know about it!{' '}
                </ConditionalFormTextStyled>
                <FieldsWrapper>
                  <Controller
                    name="casrn"
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ field: { ref, ...field } }) => (
                      <FormInput
                        {...field}
                        label="CASRN #*"
                        placeholder="CASRN #"
                        error={errors.casrn}
                        disabled={isLoading}
                      />
                    )}
                  />
                  <Controller
                    name="additive_name"
                    rules={{ required: 'This field is required' }}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <FormInput
                        {...field}
                        label="Additive Name*"
                        placeholder="Additive Name"
                        error={errors.additive_name}
                        disabled={isLoading}
                      />
                    )}
                  />
                  <Controller
                    name="reviewed_section"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <FormSingleSelect
                        {...field}
                        label="Section to be Reviewed"
                        options={REVIEW_SECTION_OPTIONS}
                        disabled={isLoading}
                      />
                    )}
                  />
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextareaStyled
                        {...field}
                        label={
                          watchInquiryType === 'data-correction'
                            ? 'Description of the data issue'
                            : watchInquiryType === 'new-information'
                            ? 'Please provide any additional information about the additive record'
                            : 'Briefly describe your inquiry'
                        }
                        disabled={isLoading}
                      />
                    )}
                  />
                  <Controller
                    name="link"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <FormLinkInput
                        {...field}
                        label="Supporting Documentation Link"
                        placeholder="Supporting Documentation Link"
                        disabled={isLoading}
                      />
                    )}
                  />
                </FieldsWrapper>
              </>
            ) : (
              <FieldsWrapper>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => {
                    const { ref, ...rest } = field;
                    return (
                      <TextareaStyled
                        {...rest}
                        label="Briefly Describe Your Inquiry"
                        disabled={isLoading}
                      />
                    );
                  }}
                />
              </FieldsWrapper>
            )}
            <Button
              type="button"
              title="Submit"
              color="green"
              size="LG"
              style={{ fontSize: '14px' }}
              onClick={handleSave}
              disabled={!isDirty || isLoading}
            />
          </Content>
        </FormWrapper>
      )}
    </>
  );
};

export default HelpButton;
