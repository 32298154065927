import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../service/api';
import { IAddress, ICountry, IState, IUser, IUserProfile, IUserRole } from 'types/index';
import { showErrorAlert, showSuccessAlert } from '../alert/alertSlice';
import { resetTokens } from '../auth/authSlice';

export type InquiryPurpose =
  | 'general-inquiry'
  | 'technical-support'
  | 'feedback-suggestions'
  | 'account-assistance'
  | 'report-issue'
  | 'collaboration-inquiry'
  | 'other'
  | 'data-correction'
  | 'new-information';

export type ReviewedSection =
  | 'overview'
  | 'chemical-identity'
  | 'physical-chemical-properties'
  | 'use'
  | 'classification-labelling'
  | 'regulation'
  | 'hazard'
  | 'exposure'
  | 'risk';

export type InquiryStatus =
  | 'new'
  | 'in-progress'
  | 'escalated'
  | 'on-hold'
  | 'resolved'
  | 'closed';

export interface IInquiry {
  id: number;
  user_id: number;
  status: InquiryStatus;
  purpose: InquiryPurpose;
  casrn: string;
  additive_name: string;
  reviewed_section: ReviewedSection;
  link: string;
  description: string;
  resolution: string;
  created_at: string;
  updated_at: string;
  email: string;
  first_name: string;
  last_name: string;
  organization: string;
  country: string;
  user?: User;
}

interface User extends IUser {
  profile: IUserProfile;
  role: IUserRole;
  address: IAddress & {
    country: ICountry;
    state: IState;
  };
}

interface IInquiryResponse {
  data: {
    resource: IInquiry & {
      user: IUser;
    };
  };
  message: string | null;
  errors: string[];
}

interface ListFilters {
  name: string;
  email: string;
  country_id: string;
  purpose: string;
  status: string;
  order_by: string;
  order_dir: string;
  page: number;
  ipp: number;
}

interface Pagination {
  total: number;
  current_page: number;
  last_page: number;
  per_page: number;
  from: number;
  to: number;
}

interface InquiryState {
  inquiries: Array<IInquiry>;
  currentInquiry: IInquiry | null;
  listFilters: ListFilters;
  pagination: Pagination;
  isLoading: boolean;
}

export const fetchInquiries = createAsyncThunk(
  'inquiries/list',
  async (params: Partial<ListFilters>) => {
    const response = await api.get('support-requests/list', { params });
    return response.data;
  },
);

export const fetchInquiryById = createAsyncThunk(
  'inquiries/get',
  async (id: string): Promise<IInquiryResponse> => {
    const response = await api.get(`support-requests/${id}/get`);
    return response.data;
  },
);

export const updateInquiry = createAsyncThunk(
  'inquiries/update',
  async (params: { id: string; data: Partial<IInquiry> }, thunkAPI) => {
    try {
      const response = await api.post(
        `support-requests/${params.id}/update`,
        params.data,
      );
      thunkAPI.dispatch(showSuccessAlert({ message: response.data.message }));
      return response.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        showErrorAlert({
          message: 'Failed to update inquiry',
          errors: error.response?.data?.errors || [error.message],
        }),
      );
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const createInquiry = createAsyncThunk(
  'inquiries/create',
  async (data: Partial<IInquiry>, thunkAPI) => {
    try {
      const response = await api.post('support-requests/create', data);
      thunkAPI.dispatch(showSuccessAlert({ message: response.data.message }));
      return response.data;
    } catch (error: any) {
      // thunkAPI.dispatch(
      //   showErrorAlert({
      //     message: 'Failed to submit inquiry',
      //     errors: error.response?.data?.errors || [error.message],
      //   }),
      // );
      // return thunkAPI.rejectWithValue({ error: error.message });
      if (error.response?.status === 401) {
        thunkAPI.dispatch(resetTokens());
        window.location.replace('/sign-in');
      }
      thunkAPI.dispatch(
        showErrorAlert({
          message: 'Failed to submit inquiry',
          errors: error.response?.data?.errors || [error.message],
        }),
      );
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const initialState: InquiryState = {
  inquiries: [],
  currentInquiry: null,
  listFilters: {
    name: '',
    email: '',
    country_id: '',
    purpose: '',
    status: '',
    order_by: 'created_at',
    order_dir: 'desc',
    page: 1,
    ipp: 10,
  },
  pagination: {
    total: 0,
    current_page: 1,
    last_page: 1,
    per_page: 10,
    from: 1,
    to: 1,
  },
  isLoading: false,
};

export const inquirySlice = createSlice({
  name: 'inquiry',
  initialState,
  reducers: {
    setFilterParams: (state, action) => {
      state.listFilters = {
        ...state.listFilters,
        ...action.payload,
      };
    },
    resetFilters: state => {
      state.listFilters = initialState.listFilters;
    },
    setCurrentInquiry: (state, action) => {
      state.currentInquiry = action.payload;
    },
    resetCurrentInquiry: state => {
      state.currentInquiry = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInquiries.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchInquiries.fulfilled, (state, action) => {
        const { data, current_page, total, last_page, per_page, from, to } =
          action.payload.data.resource;
        state.inquiries = data;
        state.pagination = {
          current_page,
          total,
          last_page,
          per_page,
          from,
          to,
        };
        state.isLoading = false;
      })
      .addCase(fetchInquiries.rejected, state => {
        state.isLoading = false;
      })

      .addCase(fetchInquiryById.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchInquiryById.fulfilled, (state, action) => {
        state.currentInquiry = action.payload.data.resource;
        state.isLoading = false;
      })
      .addCase(fetchInquiryById.rejected, state => {
        state.isLoading = false;
      })

      .addCase(updateInquiry.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateInquiry.fulfilled, (state, action) => {
        state.currentInquiry = action.payload.data.resource;
        state.isLoading = false;
      })
      .addCase(updateInquiry.rejected, state => {
        state.isLoading = false;
      })

      .addCase(createInquiry.pending, state => {
        state.isLoading = true;
      })
      .addCase(createInquiry.fulfilled, (state, action) => {
        state.currentInquiry = action.payload.data.resource;
        state.isLoading = false;
      })
      .addCase(createInquiry.rejected, state => {
        state.isLoading = false;
      });
  },
});

export const { setFilterParams, resetFilters, setCurrentInquiry, resetCurrentInquiry } =
  inquirySlice.actions;

export default inquirySlice.reducer;
