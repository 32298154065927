import React, {FC, useRef} from "react";
import PageContentContainer from "components/common/PageContentContainer/PageContentContainer";
import {DataTable} from "components/common";
import AsideBlack from "./parts/AsideBlack/AsideBlack";
import SearchPanel from "./parts/SearchPanel/SearchPanel";
import {Content, H2Styled, StyledLink, Title} from "./styled";
import {useAppDispatch, useAppSelector} from "hooks/global";
import {InquiriesTabStatus} from "types";
import {IInquiry, setFilterParams} from "store/inquiry/inquirySlice";
import {defaultTheme} from "themes/main";
import {PURPOSE_LABELS, STATUS_LABELS} from "constants/inquiryOptions";

const breadcrumbs = [
    {
        link: '/inquiries',
        name: 'Contact Inquiries Search & List View',
    }
];

const Inquiries: FC = () => {
    const dispatch = useAppDispatch();
    const filterRef = useRef<any>(null);
    const {inquiries, isLoading, listFilters, pagination} = useAppSelector(state => state.inquiry);

    const getColumns = (tab: InquiriesTabStatus) => [
        {
            name: 'Name',
            alias: 'name',
            selector: (row: IInquiry) => (
                <StyledLink to={`/inquiry/${row.id}`}>
                    {row.first_name} {row.last_name}
                </StyledLink>
            ),
            sortable: true,
        },
        {
            name: 'Email Address',
            alias: 'email',
            selector: (row: IInquiry) => row.email,
            sortable: true,
        },
        {
            name: 'Country',
            alias: 'country',
            selector: (row: IInquiry) => row.country,
            sortable: true,
        },
        {
            name: 'Purpose of Inquiry',
            alias: 'purpose',
            selector: (row: IInquiry) => PURPOSE_LABELS[row.purpose] || row.purpose,
            sortable: true,
        },
        {
            name: 'Organization Affiliation',
            alias: 'organization',
            selector: (row: IInquiry) => row.organization,
            sortable: true,
        },
        {
            name: 'Status',
            alias: 'status',
            selector: (row: IInquiry) => STATUS_LABELS[row.status] || row.status,
            sortable: true,
            style: {
                color: defaultTheme.ultravioletColor
            }
        },
    ];

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    const setStatusFilter = (tab: InquiriesTabStatus) => {
        filterRef?.current?.setStatusFilter(tab);
    };

    const getStatusFilter = (): InquiriesTabStatus => {
        return filterRef?.current?.getStatusFilter() || 'active';
    };

    return (
        <PageContentContainer
            title={'Contact Inquiries Search & List View'}
            isLoading={isLoading}
            breadcrumbs={breadcrumbs}
            aside={
                <AsideBlack setTab={setStatusFilter} tab={getStatusFilter()}/>
            }
        >
            <Title>
                <H2Styled>Contact Inquiries</H2Styled>
            </Title>
            <Content>
                <SearchPanel
                    ref={filterRef}
                />
                <DataTable
                    data={inquiries}
                    columns={getColumns(getStatusFilter())}
                    currentPage={pagination.current_page}
                    paginationTotalRows={pagination.total}
                    paginationPerPage={pagination.per_page}
                    lastPage={pagination.last_page}
                    from={pagination.from}
                    to={pagination.to}
                    onChangePage={handleChangePage}
                    onSort={handleSort}
                    pagination={true}
                />
            </Content>
        </PageContentContainer>
    );
};

export default Inquiries;
