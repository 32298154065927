import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../service/api';

export interface IAnalyticsItem {
  user_id: number;
  last_login: string;
  sessions_count: number;
  user_role: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  country: string;
  sector: string;
  organization_affiliation: string;
  status: string;
  avg_pages: number;
  pages_accessed: number;
  session_time: string;
}

interface ListFilters {
  order_by: string;
  order_dir: string;
  page: number;
  ipp: number;
  first_name: string;
  last_name: string;
  email: string;
  date_from: string | null;
  date_to: string | null;
}

interface Pagination {
  total: number;
  current_page: number;
  last_page: number;
  per_page: number;
  from: number;
  to: number;
}

interface AnalyticsState {
  analytics: Array<IAnalyticsItem>;
  listFilters: ListFilters;
  pagination: Pagination;
  isLoading: boolean;
}

export const fetchAnalytics = createAsyncThunk('analytics/list', async (params: any) => {
  const response = await api.get('google-analytics/list', { params });
  return response.data;
});

const initialState: AnalyticsState = {
  analytics: [],
  listFilters: {
    order_by: 'last_login',
    order_dir: 'desc',
    page: 1,
    ipp: 25,
    first_name: '',
    last_name: '',
    email: '',
    date_from: null,
    date_to: null,
  },
  pagination: {
    total: 0,
    current_page: 1,
    last_page: 1,
    per_page: 1,
    from: 1,
    to: 1,
  },
  isLoading: false,
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setFilterParams: (state, action) => {
      state.listFilters = action.payload;
    },
    resetFilters: state => {
      state.listFilters = initialState.listFilters;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAnalytics.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        const { data, current_page, total, last_page, per_page, from, to } =
          action.payload.data.resource;
        state.analytics = data;
        state.pagination = {
          ...state.pagination,
          current_page: current_page,
          total: total,
          last_page: last_page,
          per_page: per_page,
          from: from,
          to: to,
        };
        state.isLoading = false;
      })
      .addCase(fetchAnalytics.rejected, state => {
        state.isLoading = false;
      })
      .addDefaultCase(() => {});
  },
});

export const { setFilterParams, resetFilters } = analyticsSlice.actions;

export default analyticsSlice.reducer;
