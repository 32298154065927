import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/global';
import { Button } from 'components/controls';
import { ICountry, INQUIRY_PURPOSE_OPTIONS, ISelectOption } from 'types/index';
import { fetchInquiries, setFilterParams } from 'store/inquiry/inquirySlice';
import { fetchCountries } from 'store/general/generalSlice';
import {
  FormInput,
  FormSingleSelect,
  SearchPanelButtons,
  SearchPanelButtonsGroup,
  SearchPanelControls,
  SearchPanelWrapper,
} from './styled';

const SearchPanel = forwardRef(({ isViewOnly }: { isViewOnly?: boolean }, ref) => {
  const dispatch = useAppDispatch();
  const didMountRef = useRef(false);
  const { countries } = useAppSelector(state => state.general);
  const { listFilters } = useAppSelector(state => state.inquiry);
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: listFilters.name,
      email: listFilters.email,
      country_id: listFilters.country_id,
      purpose: listFilters.purpose,
      status: listFilters.status,
    },
  });

  useImperativeHandle(ref, () => ({
    setStatusFilter(value: string) {
      setValue('status', value);
      handleSubmit(handleSearch)();
    },
    getStatusFilter() {
      return getValues('status');
    },
  }));

  const handleReset = () => {
    const data = {
      name: '',
      email: '',
      country_id: '',
      purpose: '',
      status: listFilters.status,
    };
    reset(data);
    dispatch(setFilterParams({ ...listFilters, ...data, page: 1 }));
  };

  const handleSearch = (data: any) => {
    dispatch(setFilterParams({ ...listFilters, ...data, page: 1 }));
  };

  const countriesOptions = useMemo(() => {
    return countries
      ? countries.map(
          (item: ICountry): ISelectOption => ({ label: item.title, value: item.id }),
        )
      : [];
  }, [countries]);

  useEffect(() => {
    didMountRef.current = true;
    handleReset();
    dispatch(fetchCountries());
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      dispatch(fetchInquiries(listFilters));
    }
  }, [listFilters]);

  return (
    <SearchPanelWrapper>
      <SearchPanelControls>
        <Controller
          name="name"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput {...field} label="Name" placeholder="" />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormInput {...field} label="Email Address" placeholder="" />
          )}
        />
        <Controller
          name="country_id"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormSingleSelect {...field} label="Country" options={countriesOptions} />
          )}
        />
        <Controller
          name="purpose"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormSingleSelect
              {...field}
              label="Purpose of Inquiry"
              options={INQUIRY_PURPOSE_OPTIONS}
            />
          )}
        />
      </SearchPanelControls>
      <SearchPanelButtons>
        <SearchPanelButtonsGroup>
          <Button
            title="SEARCH"
            color="green"
            size="LG"
            variant="secondary"
            onClick={handleSubmit(handleSearch)}
          />
          <Button
            title="RESET"
            color="red-transparent"
            size="LG"
            variant="secondary"
            onClick={handleReset}
          />
        </SearchPanelButtonsGroup>
      </SearchPanelButtons>
    </SearchPanelWrapper>
  );
});

SearchPanel.displayName = 'SearchPanel';

export default SearchPanel;
