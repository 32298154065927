import React, { FC, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment-timezone';
import { FieldError } from 'react-hook-form';
import { Container, Error, IconImg, StyledLabel, Wrapper } from './styled';

export interface DatepickerProps {
  className?: string;
  label?: string;
  value: Moment | null;
  onChange: (value: Moment | null) => void;
  disabled?: boolean;
  inputSize?: 'XL' | 'MD' | 'SM';
  error?: FieldError;
  icon?: string;
}

const DatepickerMUIStyled: FC<DatepickerProps> = ({
  className,
  label = '',
  value,
  onChange,
  disabled = false,
  inputSize = 'MD',
  error,
  icon,
  ...props
}) => {
  const [val, setVal] = React.useState<Moment | null>(
    moment().tz('UTC').set({ hour: 12, minute: 0 }),
  );

  useEffect(() => {
    if (value) setVal(value);
  }, [value]);

  return (
    <Wrapper className={className}>
      {!!label.length && <StyledLabel>{label}</StyledLabel>}
      <Container $inputSize={inputSize}>
        {!!icon && <IconImg src={icon} width={20} height={20} alt="" />}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={val}
            disabled={disabled}
            onChange={newValue => {
              setVal(newValue);
              onChange(newValue);
            }}
            slotProps={{
              textField: {
                size: 'small',
                error: !!error,
                fullWidth: true,
                InputProps: {
                  style: icon ? { paddingLeft: '40px' } : undefined,
                },
              },
            }}
            {...props}
          />
        </LocalizationProvider>
      </Container>
      {!!error && error.message && (
        <Error className={'errorMsg'}>{`${error.message}`}</Error>
      )}
    </Wrapper>
  );
};

export default DatepickerMUIStyled;
