import ReactGA from 'react-ga4';
import { store } from '../store/store';

declare global {
  interface Window {
    gtag?: (command: string, action: string | any, params?: Record<string, any>) => void;
  }
}

let sessionStartTimestamp: number | null = null;
let lastActivityTimestamp: number | null = null;
let isSessionActive = false;

export const initGA = (measurementId: string): void => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(measurementId);
    startSession();
    setupSessionTracking();
  } else {
    console.log('GA not initialized (development mode)');
  }
};

export const setUserId = (userId: string): void => {
  if (userId && process.env.NODE_ENV === 'production') {
    ReactGA.set({ user_id: userId });

    ReactGA.event({
      category: 'User',
      action: 'login',
      nonInteraction: false,
    });

    startSession();
  }
};

export const setUserProperties = (
  userProperties: Record<string, string | number | boolean>,
): void => {
  if (userProperties && process.env.NODE_ENV === 'production') {
    ReactGA.set(userProperties);
  }
};

export const pageView = (path: string): void => {
  if (process.env.NODE_ENV === 'production') {
    const userId = getCurrentUserId();

    updateActivity();

    ReactGA.send({
      hitType: 'pageview',
      page: path,
      user_id: userId,
    });

    ReactGA.event({
      category: 'Page',
      action: 'page_view',
      label: path,
    });
  }
};

export const trackEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
): void => {
  if (process.env.NODE_ENV === 'production') {
    const userId = getCurrentUserId();

    updateActivity();

    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  }
};

function getCurrentUserId(): string | undefined {
  const state = store.getState();

  if (state.auth && state.auth.user && state.auth.user.id) {
    return state.auth.user.id.toString();
  }

  return undefined;
}

function startSession() {
  if (process.env.NODE_ENV === 'production') {
    const userId = getCurrentUserId();
    sessionStartTimestamp = Date.now();
    isSessionActive = true;

    ReactGA.event({
      category: 'Session',
      action: 'session_start',
      nonInteraction: true,
    });

    lastActivityTimestamp = Date.now();
  }
}

function endSession() {
  if (process.env.NODE_ENV === 'production' && isSessionActive) {
    // const userId = getCurrentUserId();
    const sessionEndTimestamp = Date.now();

    ReactGA.event({
      category: 'Session',
      action: 'session_end',
      nonInteraction: true,
    });

    if (sessionStartTimestamp) {
      const sessionDuration = sessionEndTimestamp - sessionStartTimestamp;

      ReactGA.event({
        category: 'Session',
        action: 'session_duration',
        value: Math.floor(sessionDuration / 1000), // в секундах
        nonInteraction: true,
      });
    }

    isSessionActive = false;
    sessionStartTimestamp = null;
  }
}

function updateActivity() {
  if (process.env.NODE_ENV === 'production' && isSessionActive) {
    const currentTime = Date.now();

    if (lastActivityTimestamp) {
      const engagementTime = currentTime - lastActivityTimestamp;

      if (engagementTime > 1000) {
        ReactGA.event({
          category: 'User',
          action: 'user_engagement',
          value: Math.floor(engagementTime / 1000), // в секундах
          nonInteraction: true,
        });
      }
    }

    lastActivityTimestamp = currentTime;
  }
}

function setupSessionTracking() {
  if (typeof window !== 'undefined') {
    setInterval(() => {
      if (isSessionActive) {
        updateActivity();
      }
    }, 30000);

    window.addEventListener('beforeunload', () => {
      endSession();
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        updateActivity();
      } else {
        lastActivityTimestamp = Date.now();
      }
    });

    let inactivityTimeout: number | undefined;

    const resetInactivityTimer = () => {
      if (inactivityTimeout) {
        clearTimeout(inactivityTimeout);
      }

      inactivityTimeout = window.setTimeout(
        () => {
          if (isSessionActive) {
            endSession();
          }
        },
        30 * 60 * 1000,
      ); // 30 минут
    };

    ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach(event => {
      document.addEventListener(event, resetInactivityTimer, true);
    });

    resetInactivityTimer();
  }
}

export default {
  initGA,
  setUserId,
  setUserProperties,
  pageView,
  trackEvent,
};
