import styled from 'styled-components';
import { DatepickerProps } from './DatePickerMUIStyled';

export const Wrapper = styled.div`
  display: block;
`;

export const Container = styled.div<{ $inputSize: DatepickerProps['inputSize'] }>`
  display: flex;
  position: relative;

  ${({ $inputSize }) =>
    $inputSize &&
    ($inputSize === 'XL'
      ? { height: 52 }
      : $inputSize === 'MD'
      ? { height: 44 }
      : $inputSize === 'SM'
      ? { height: 40 }
      : {})}

  // Настройка компонентов MUI
  & .MuiFormControl-root {
    width: 100%;
  }

  & .MuiInputBase-root {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 2px;
  }

  & .MuiOutlinedInput-root {
    border-radius: 2px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d0e0e5;
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.blueColor};
  }

  & .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.errorColor};
  }

  & .MuiInputBase-input {
    font-weight: 400;
    font-size: 16px;
    color: ${props => props.theme.blackTextColor};
  }

  & .MuiInputBase-input::placeholder {
    font-weight: 400;
    color: #a4b1b4;
  }

  & .Mui-disabled .MuiOutlinedInput-notchedOutline {
    background-color: ${props => props.theme.disabledBackgroundColor};
    border-color: #d0e0e5;
  }

  & .MuiInputBase-input.Mui-disabled {
    color: ${props => props.theme.disabledTextColor};
    -webkit-text-fill-color: ${props => props.theme.disabledTextColor};
  }
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: ${props => props.theme.greyColor};
`;

export const IconImg = styled.img`
  position: absolute;
  left: 10px;
  top: 12px;
  z-index: 2;
`;

export const Error = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${props => props.theme.errorColor};
`;
