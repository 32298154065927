import styled from "styled-components";
import { Input, SingleSelect, Textarea } from "components/controls";

export const Title = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E4E4E4;
`;

export const Content = styled.form`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 40px;
`;

export const H2Styled = styled.h2`
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: ${(props) => props.theme.fontFamilySecondary};
  max-width: 850px;
  word-break: break-all;
`;

export const FormInput = styled(Input)`
  width: 100%;
  max-width: 277px;
  min-width: 150px;
`;

export const FormLinkInput = styled(Input)`
  width: 100%;
  max-width: 584px;
  min-width: 150px;
`;

export const FormSingleSelect = styled(SingleSelect)`
  width: 100%;
  max-width: 277px;
`;

export const TextareaStyled = styled(Textarea)`
  width: 100%;
  max-width: 1200px;
  & textarea {
    resize: none;
    min-height: 120px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: max-content;
`;
