import { InquiryPurpose, InquiryStatus } from 'store/inquiry/inquirySlice';

export const PURPOSE_LABELS: Record<InquiryPurpose, string> = {
  'general-inquiry': 'General Inquiry',
  'technical-support': 'Technical Support',
  'feedback-suggestions': 'Feedback & Suggestions',
  'account-assistance': 'Account Assistance',
  'report-issue': 'Report Issue',
  'collaboration-inquiry': 'Collaboration Inquiry',
  'data-correction': 'Request Data Update/Correction',
  'new-information': 'Submit New Additive Information',
  other: 'Other',
};

export const STATUS_LABELS: Record<InquiryStatus, string> = {
  'in-progress': 'In Progress',
  escalated: 'Escalated',
  'on-hold': 'On Hold',
  resolved: 'Resolved',
  closed: 'Closed',
  new: 'New',
};

export const STATUS_OPTIONS = [
  { value: 'new', label: 'New' },
  { value: 'in-progress', label: 'In Progress' },
  { value: 'escalated', label: 'Escalated' },
  { value: 'on-hold', label: 'On Hold' },
  { value: 'resolved', label: 'Resolved' },
  { value: 'closed', label: 'In Closed' },
];

export const REVIEW_SECTION_OPTIONS = [
  { value: 'overview', label: 'Overview' },
  { value: 'chemical-identity', label: 'Chemical Identity' },
  { value: 'physical-chemical-properties', label: 'Physical Chemical Properties' },
  { value: 'use', label: 'Use' },
  { value: 'classification-labelling', label: 'Classification & Labelling' },
  { value: 'regulation', label: 'Regulation' },
  { value: 'hazard', label: 'Hazard' },
  { value: 'exposure', label: 'Exposure' },
  { value: 'risk', label: 'Risk' },
];
