import { Control } from 'react-hook-form/dist/types';

export interface IUsersListItem {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  function: string;
  sector: string;
  organization: string;
  role: string;
  verified_at: string;
  is_active: number;
}

export interface IUser {
  id: number;
  email: string;
  is_active: number;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
}

export interface IUserProfile {
  id: number;
  first_name: string;
  last_name: string;
  organization: string;
  function: string;
  sector: string;
  time_zone: string;
  is_agree?: number;
}

export interface IUserRole {
  id: number;
  alias: string;
  title: string;
  description: string;
  is_active: number;
}

export interface IAddress {
  id: number;
  type: string;
  country_id: number;
  state_id: number;
  state: string;
  province: string;
  full_address: string;
  address: string;
  address2: string;
  building: string;
  apartment: string;
  city: string;
  zip: string;
  phone: string;
  phone2: string;
  fax: string;
  cell_phone: string;
  email: string;
  email2: string;
  website: string;
  gallery: string;
  lat: number;
  lon: number;
  place_id: string;
}

export interface IState {
  id: number | null;
  country_id: number;
  title: string;
  code: string;
  is_active: number;
}

export interface ICountry {
  id: number;
  title: string;
  code: string;
  is_active: number;
}

export interface IEntityType {
  id: number;
  title: string;
  alias: string;
}

export interface IUserFormData {
  email: string;
  first_name: string;
  last_name: string;
  role?: string;
  password: string;
  country_id: number | null;
  function: string;
  sector: string;
  organization: string;
}

export interface ICustomFields {
  [custom: string]: string;
}

export interface ISelectOption {
  label: string;
  value: string | number | boolean | null;
}

export interface IChemicalOption {
  id: number;
  title: string;
  alias: string;
  type: 'unep' | 'survey' | 'both';
}

export interface ICitation {
  casrn: string;
  jurisdiction: string;
  citation: string;
  url: string;
  summary: string;
}

export interface IGHS {
  casrn: string;
  note: string;
  signal: string;
  statements: string;
  summary: string;
}

export interface IClassification {
  casrn: string;
  ghs: string;
  jurisdiction: string;
  signal: string;
  symbol: string;
  code: string;
  class: string;
  statement: string;
}

export interface IChemical {
  id: number;
  substance_id: number | null;
  casrn: string;
  name: string;
  preferred_name: string | null;
  inchi_key: string | null;
  inchi_string: string | null;
  ec: string | null;
  smiles: string | null;
  molecular_formula: string | null;
  molecular_mass_isotope: string | null;
  alternative_casrn_scifinder: string | null;
  deleted_casrn_scifinder: string | null;
  verified_in_commerce: boolean | null;
  uvcb: boolean | null;
  organic: boolean | null;
  contains_metal: boolean | null;
  contains_silicon: boolean | null;
  contains_phosphorous: boolean | null;
  contains_sulfur: boolean | null;
  contains_halogen: boolean | null;
  confidence_commerce: 'high' | 'low' | 'medium' | null;
  confidence_plastic: 'high' | 'low' | 'medium' | null;
  confidence_additives: 'high' | 'low' | 'medium' | null;
  pbt: number | null;
  vpvb: number | null;
  perisistent_P: number | null;
  very_persistent_vp: number | null;
  bioaccumulative_b: number | null;
  very_bioaccumulative_vb: number | null;
  cmr: number | null;
  carcinogenic_c: number | null;
  mutagenic_m: number | null;
  reprotoxic_r: number | null;
  specific_target_organ_toxicity_repeated_exposure_stot_re: number | null;
  aquatic_toxicity_aqtox: number | null;
  respiratory_sensitization_respsens: number | null;
  endocrine_disrupting_edc: number | null;
  montreal: boolean | null;
  us_ozone_depleting_substances: boolean | null;
  eu_controlled_substances_montreal: boolean | null;
  stockholm: boolean | null;
  eu_pop_directive: boolean | null;
  rotterdam: string | null;
  eu_priority_informed_concent: boolean | null;
  reach_restriction: boolean | null;
  reach_authorisation: boolean | null;
  reach_svhc: boolean | null;
  rohs_directive: boolean | null;
  toys_directive: boolean | null;
  california_proposition65: boolean | null;
  cscl_classi_restriction: boolean | null;
  cscl_classii_authorization: boolean | null;
  isha_restriction: boolean | null;
  isha_authorisation: boolean | null;
  kreach_restriction: boolean | null;
  kreach_authorisation: boolean | null;
  food_contact_positive_list: string;
  relevant_food_contact_positive_lists: boolean | null;
  eu_food_contact_materials_list: boolean | null;
  usa_generally_recognized_as_safe: boolean | null;
  japan_food_contact_materials_list: boolean | null;
  function: string;
  polymer: string;
  industrial_sector: string;
  industrial_sector_food_contact: boolean | null;
  number_regions: number | null;
  regions: string;
  eu: number | null;
  nordic_countries_spin: number | null;
  usa: number | null;
  oecd: number | null;
  total: number | null;
  chemical_structure: string | null;
  is_active: number | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  functions?: Array<IChemicalOption>;
  polymers?: Array<IChemicalOption>;
  industrial_sectors?: Array<IChemicalOption>;
  synonyms: string | null;
  dtxsid: string | null;
  dtxsid_link: string | null;
  polarizability_avg: string | null;
  polarizability_low: string | null;
  polarizability_high: string | null;
  polarizability_unit: string | null;
  henrys_law_avg: string | null;
  henrys_law_low: string | null;
  henrys_law_high: string | null;
  henrys_law_unit: string | null;
  boiling_point_avg: string | null;
  boiling_point_low: string | null;
  boiling_point_high: string | null;
  boiling_point_unit: string | null;
  flash_point_avg: string | null;
  flash_point_low: string | null;
  flash_point_high: string | null;
  flash_point_unit: string | null;
  melting_point_avg: string | null;
  melting_point_high: string | null;
  melting_point_low: string | null;
  melting_point_unit: string | null;
  molar_refractivity_avg: string | null;
  molar_refractivity_high: string | null;
  molar_refractivity_low: string | null;
  molar_refractivity_unit: string | null;
  molar_volume_avg: string | null;
  molar_volume_high: string | null;
  molar_volume_low: string | null;
  molar_volume_unit: string | null;
  viscosity_avg: string | null;
  viscosity_high: string | null;
  viscosity_low: string | null;
  viscosity_unit: string | null;
  surface_tension_avg: string | null;
  surface_tension_high: string | null;
  surface_tension_low: string | null;
  surface_tension_unit: string | null;
  density_avg: string | null;
  density_low: string | null;
  density_high: string | null;
  density_unit: string | null;
  vapor_pressure_avg: string | null;
  vapor_pressure_low: string | null;
  vapor_pressure_high: string | null;
  vapor_pressure_unit: string | null;
  water_solubility_avg: string | null;
  water_solubility_low: string | null;
  water_solubility_high: string | null;
  water_solubility_unit: string | null;
  thermal_conductivity_avg: string | null;
  thermal_conductivity_low: string | null;
  thermal_conductivity_high: string | null;
  thermal_conductivity_unit: string | null;
  refraction_index_avg: string | null;
  refraction_index_low: string | null;
  refraction_index_high: string | null;
  refraction_index_unit: string | null;
  logkoa_octanol_air_avg: string | null;
  logkoa_octanol_air_low: string | null;
  logkoa_octanol_air_high: string | null;
  logkoa_octanol_air_unit: string | null;
  logkow_octanol_water_avg: string | null;
  logkow_octanol_water_low: string | null;
  logkow_octanol_water_high: string | null;
  logkow_octanol_water_unit: string | null;
  pka_acidic_apparent_avg: string | null;
  pka_acidic_apparent_low: string | null;
  pka_acidic_apparent_high: string | null;
  pka_acidic_apparent_unit: string | null;
  pka_basic_apparent_avg: string | null;
  pka_basic_apparent_low: string | null;
  pka_basic_apparent_high: string | null;
  pka_basic_apparent_unit: string | null;
  fish_biotransformation_halflife_avg: string | null;
  fish_biotransformation_halflife_low: string | null;
  fish_biotransformation_halflife_high: string | null;
  fish_biotransformation_halflife_unit: string | null;
  bioconcentration_factor_avg: string | null;
  bioconcentration_factor_low: string | null;
  bioconcentration_factor_high: string | null;
  bioconcentration_factor_unit: string | null;
  soil_adsorption_coefficient_avg: string | null;
  soil_adsorption_coefficient_low: string | null;
  soil_adsorption_coefficient_high: string | null;
  soil_adsorption_coefficient_unit: string | null;
  ready_biodegredation_avg: string | null;
  ready_biodegredation_low: string | null;
  ready_biodegredation_high: string | null;
  ready_biodegredation_unit: string | null;
  atmospheric_hydroxylation_rate_avg: string | null;
  atmospheric_hydroxylation_rate_low: string | null;
  atmospheric_hydroxylation_rate_high: string | null;
  atmospheric_hydroxylation_rate_unit: string | null;
  biodegregation_halflife_avg: string | null;
  biodegregation_halflife_low: string | null;
  biodegregation_halflife_high: string | null;
  biodegregation_halflife_unit: string | null;
  bioaccumulation_factor_avg: string | null;
  bioaccumulation_factor_low: string | null;
  bioaccumulation_factor_high: string | null;
  bioaccumulation_factor_unit: string | null;
  skin_sensitizer: string | null;
  is_unep: number | null;
  is_survey: number | null;
  is_plasi: number | null;
  is_special_chem: number | null;
  is_limited_data: number | null;
  citations?: Array<ICitation>;
  classifications?: Array<IClassification>;
  ghs?: Array<IGHS>;
}

export interface ITab {
  alias:
    | 'overview'
    | 'identity'
    | 'properties'
    | 'use'
    | 'classification'
    | 'regulation'
    | 'hazard'
    | 'exposure'
    | 'risk';
  title: string;
}

export interface IValue {
  value: string | number | boolean | null;
}

export interface IField {
  name: keyof IChemical;
  label: string;
  category: string;
  sub_category: string;
  defaultValue: string | null;
  show_if_true: boolean;
  is_multilines: boolean;
  is_evidence_translate?: boolean;
  is_uppercase?: boolean;
  rules: { [key: string]: string };
  type: 'input' | 'select' | 'boolean' | 'image';
  attrs: { [key: string]: string | boolean };
  options: Array<ISelectOption>;
  desc: string;
  value?: IValue['value'];
}

export interface IFormField {
  name: keyof IChemical;
  type: 'input' | 'select' | 'boolean' | 'image';
  label?: string;
  placeholder?: string;
  defaultValue?: string | null;
  show_if_true?: boolean;
  is_multilines?: boolean;
  is_evidence_translate?: boolean;
  is_uppercase?: boolean;
  rules?: { [key: string]: string };
  attrs?: { [key: string]: string | boolean };
  options?: Array<ISelectOption>;
  value?: IValue['value'];
}

export interface IFieldCategory {
  category: string;
  sub_category: string;
  label: string;
  fields: Array<IField>;
}

export interface IResult {
  [key: string]: string | number | boolean | null;
}

export interface IGlossaryField {
  id: number;
  alias: keyof IChemical;
  title: string;
  label: string;
  type: string;
  sub_type: string;
  example: string;
  desc: string;
  desc_orig: string;
  hidden: boolean;
  created_at?: string;
  updated_at?: string;
}

export type UserStatus = 'active' | 'pending' | 'archived';

export interface IForm {
  control: Control<any>;
  errors: any;
}

export type InquiriesTabStatus = 'active' | 'closed';

export const INQUIRY_PURPOSE_OPTIONS = [
  { value: 'general-inquiry', label: 'General Inquiry' },
  { value: 'technical-support', label: 'Technical Support' },
  { value: 'feedback-suggestions', label: 'Feedback & Suggestions' },
  { value: 'account-assistance', label: 'Account Assistance' },
  { value: 'report-issue', label: 'Report Issue' },
  { value: 'collaboration-inquiry', label: 'Collaboration Inquiry' },
  { value: 'data-correction', label: 'Request Data Update/Correction' },
  { value: 'new-information', label: 'Submit New Additive Information' },
  { value: 'other', label: 'Other' },
];
