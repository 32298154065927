import React, { useEffect } from 'react';
import Router from './router/Router';
import { AlertModal } from './components/common';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from './hooks/global';
import { initGA, setUserId, pageView } from './service/analytics';
import { GA_MEASUREMENT_ID } from './constants/ga4';

function App() {
  const location = useLocation();
  const { user } = useAppSelector(state => state.auth);

  useEffect(() => {
    initGA(GA_MEASUREMENT_ID);
  }, []);

  useEffect(() => {
    pageView(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id.toString());
    }
  }, [user]);

  return (
    <>
      <Router />
      <AlertModal />
    </>
  );
}

export default App;
