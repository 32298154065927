import styled from 'styled-components';
import { Input, SingleSelect, Textarea } from 'components/controls';

export const StyledButton = styled.button`
  position: relative;
  margin-top: 60px;
  padding: 16px 32px;
  background: #008d58;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }
`;

export const ButtonText = styled.span`
  color: #fbfdff;
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const Content = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 40px;
`;

export const FormWrapper = styled.div`
  margin-top: 20px;
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h2`
  font-family: 'Mulish', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const FormInput = styled(Input)`
  width: 100%;
  max-width: 277px;
  min-width: 150px;
`;

export const FormSingleSelect = styled(SingleSelect)`
  width: 100%;
  max-width: 277px;
`;

export const TextareaStyled = styled(Textarea)`
  width: 100%;
  max-width: 1200px;
  & textarea {
    resize: none;
    min-height: 120px;
  }
`;

export const FormLinkInput = styled(Input)`
  width: 100%;
  max-width: 584px;
  min-width: 150px;
`;

export const FormTextStyled = styled.div`
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 24px;
`;

export const ConditionalFormTextStyled = styled.div`
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 20px;
`;
