import React, {FC} from 'react';
import {StyledAside, StyledButton} from "./styled";
import {InquiriesTabStatus} from "types";

type Props = {
    tab: InquiriesTabStatus,
    setTab: (value: InquiriesTabStatus) => void;
}

const tabs: Array<{alias: InquiriesTabStatus, title: string}> = [
    {
        alias: 'active',
        title: 'Active',
    },
    {
        alias: 'closed',
        title: 'Closed',
    },
]

const AsideBlack: FC<Props> = ({setTab, tab = 'active'}) => {
    return (
        <StyledAside>
            <>
                {tabs.map((item) => (
                    <StyledButton
                        key={item.alias}
                        title={item.title}
                        variant='aside-black'
                        size='XL'
                        color='transparent'
                        active={tab === item.alias}
                        onClick={() => setTab(item.alias)}
                    />
                ))}
            </>
        </StyledAside>
    );
};

export default AsideBlack;
