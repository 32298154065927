import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import {showSuccessAlert} from "../alert/alertSlice";
import {IAddress, IUser, IUserProfile, IUserRole} from "../../types";
import { trackEvent, setUserId, setUserProperties } from '../../service/analytics';

export const fetchSignIn = createAsyncThunk('auth/sign-in', async (params: {username: string, password: string, otp?: string}, thunkAPI) => {
    try {
        const response = await api.post('auth/sign-in', params);

        if (response.status === 200) {
            const { resource } = response.data.data;

            trackEvent('Authentication', 'Login', 'Success');

            if (resource && resource.id) {
                setUserId(resource.id.toString());
            }
        }

        return {...response.data, code: response.status};
    } catch (error) {
        trackEvent('Authentication', 'Login', 'Failed');
        return thunkAPI.rejectWithValue(error);
    }
});

export const fetchSignUp = createAsyncThunk('auth/sign-up', async (params: any, thunkAPI) => {
    const {data, cb} = params;
    try {
        const response = await api.post('auth/sign-up', data);
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message, cb}));

        trackEvent('Authentication', 'Sign Up', 'Success');

        return response.data;
    } catch (error: any) {
        trackEvent('Authentication', 'Sign Up', 'Failed');
        return thunkAPI.rejectWithValue({ error: error.message });
    }}
);

export const fetchUser = createAsyncThunk('auth/user', async (_, thunkAPI) => {
    try {
        const response = await api.get('auth/user');

        if (response.data.data.resource) {
            const user = response.data.data.resource;

            if (user.id) {
                setUserId(user.id.toString());
            }

            if (user.profile) {
                setUserProperties({
                    userRole: user.role?.alias || 'unknown',
                    userOrganization: user.profile.organization || 'unknown',
                    userSector: user.profile.sector || 'unknown',
                    accountCreated: user.created_at || 'unknown'
                });
            }
        }

        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const fetchLogout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
    try {
        const response = await api.get('auth/logout');

        trackEvent('Authentication', 'Logout', 'Success');

        setUserId('');

        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const fetchForgotPassword = createAsyncThunk('auth/forgot-password', async (params: {username: string}, thunkAPI) => {
    try {
        const response = await api.post('auth/forgot-password', params);
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message}));

        trackEvent('Authentication', 'Forgot Password', 'Request');

        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }}
);

export const fetchSetPassword = createAsyncThunk('auth/set-password', async (params: {token: string, password: string}, thunkAPI) => {
    try {
        const response = await api.post('auth/set-password', params);
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message}));

        trackEvent('Authentication', 'Reset Password', 'Success');

        return response.data;
    } catch (error: any) {
        trackEvent('Authentication', 'Reset Password', 'Failed');
        return thunkAPI.rejectWithValue({ error: error.message });
    }}
);

export const fetchUpdateProfile = createAsyncThunk('auth/update-profile', async (params: {data: any}, thunkAPI) => {
    try {
        const response = await api.post('auth/update-profile', params.data);
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message}));

        trackEvent('User', 'Update Profile', 'Success');

        return response.data;
    } catch (error: any) {
        trackEvent('User', 'Update Profile', 'Failed');
        return thunkAPI.rejectWithValue({ error: error.message });
    }}
);

export const fetchUserDelete = createAsyncThunk('auth/delete-user', async (_, thunkAPI) => {
    try {
        const response = await api.post('auth/delete-user');
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message}));

        trackEvent('User', 'Delete Account', 'Success');

        setUserId('');

        return response.data;
    } catch (error: any) {
        trackEvent('User', 'Delete Account', 'Failed');
        return thunkAPI.rejectWithValue({ error: error.message });
    }}
);

interface AuthState {
    accessToken: string | null;
    refreshToken: string | null;
    user: IUser & {profile: IUserProfile, address: IAddress, role: IUserRole} | null;
    role: string | null;
    isLoading: boolean;
}

const initialState: AuthState = {
    accessToken: null,
    refreshToken: null,
    user: null,
    role: null,
    isLoading: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTokens: (state, action: any) => {
            const {access_token, refresh_token} = action;
            if (access_token) {
                localStorage.setItem('access_token', access_token);
                state.accessToken = access_token;
            }
            if (refresh_token) {
                localStorage.setItem('refresh_token', refresh_token);
                state.refreshToken = refresh_token;
            }
        },
        resetTokens: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('role');

            trackEvent('Authentication', 'Reset Tokens', 'Session Expired');

            setUserId('');
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSignUp.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSignUp.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchSignUp.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchSignIn.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSignIn.fulfilled, (state: any, action: any) => {
                if (action.payload.code === 200) {
                    const {credentials: {access_token, refresh_token}, role: {alias}} = action.payload.data.resource;
                    localStorage.setItem('access_token', access_token);
                    localStorage.setItem('refresh_token', refresh_token);
                    localStorage.setItem('role', alias);
                    return {...state, isLoading: false, accessToken: access_token, refreshToken: refresh_token, role: alias};
                } else {
                    return {...state, isLoading: false}
                }
            })
            .addCase(fetchSignIn.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUser.fulfilled, (state: any, action: any) => {
                const {resource} = action.payload.data
                state.user = resource;
                state.role = resource.role.alias || null;
                localStorage.setItem('role', resource.role.alias || null);
            })
            .addCase((fetchLogout.fulfilled || fetchLogout.rejected), state => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('role');
                return {...state, accessToken: null, refreshToken: null};
            })
            .addCase(fetchForgotPassword.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchForgotPassword.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchForgotPassword.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchSetPassword.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSetPassword.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchSetPassword.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateProfile.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateProfile.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateProfile.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUserDelete.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUserDelete.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchUserDelete.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(state => {
                state.isLoading = false;
            });
    },
});

export const { setTokens, resetTokens } = authSlice.actions;
export default authSlice.reducer;
