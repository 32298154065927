import styled from 'styled-components';

export const Title = styled.div`
  margin-bottom: 27px;
  border-bottom: 1px solid #e4e4e4;
`;

export const Content = styled.div``;

export const H2Styled = styled.h2`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: ${props => props.theme.fontFamilySecondary};
`;
